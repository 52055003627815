<template>
  <div>
    <div class="header-video">
      <video :src="loadUrl.studio_banner" autoplay loop muted playsinline webkit-playsinline></video>
      <div class="video-text">
        <div class="logo-text">
          <span>混空</span>
          <span>Studio</span>
        </div>
        <div class="link-room">打造数实融合的空间计算平台</div>
        <a class="experience-now" href="https://mixedspace.njyjxr.com">
          <span>立即体验</span>
          <div class="experience-btn"></div>
        </a>
      </div>
    </div>
    <div class="role-wrap">
      <div class="title">专业的元宇宙设计工具</div>
      <div class="title-tips">满足复杂场景设计需求</div>
      <div class="role-scene">
        <div v-for="(item, index) in roleTextList" :key="index"
          :class="activeRoleIndex == index ? item.activeClass : ''" @click="activeRoleIndex = index">
          <div class="role-title">{{ item.title }}</div>
          <div class="role-title-tips">{{ item.tips }}</div>
          <img :src="item.url" :class="index == 1 ? 'rokid' : ''">
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="title deep">我们的优势</div>
      <div class="title-tips deep">更低的创作门槛，更丰富的功能</div>
      <div class="m1">
        <div>
          <img class="m1-img" src="@/assets/images/studio/m1-l.png" />
          <div class="t1">更准确的空间定位</div>
          <div class="t2">自研空间定位算法，原米级视觉定位空间导航精准， 支持大空间定位</div>
        </div>
        <div>
          <img src="@/assets/images/studio/m1-r.png" />
          <div class="t1">更快速的交付部署</div>
          <div class="t2">SaaS化服务快速调整内容布局</div>
          <div class="t2">2天内完成空间构建现场体验式交付</div>
        </div>
      </div>
      <div class="m2">
        <img class="img2" src="@/assets/images/studio/m2-l.png" />
        <img class="img1" src="@/assets/images/studio/m2-m.png" />
        <img class="img3" src="@/assets/images/studio/m2-r.png" />
        <div class="t1">更简单的场景编辑</div>
        <div class="t2">3D场景地图， 可视化交互“拖拉拽“操作，所“建”即所得</div>
      </div>
      <div class="m3">
        <div>
          <div class="m3-icons">
            <img src="@/assets/images/studio/m3-l-1.png" />
            <img src="@/assets/images/studio/m3-l-2.png" />
            <img src="@/assets/images/studio/m3-l-3.png" />
          </div>
          <div class="t1">更多平台支持应用</div>
          <div class="t2">AR/MR/VR眼镜、移动端APP、小程序AR、Web AR</div>
        </div>
        <div>
          <div class="m3-r">
            <img src="@/assets/images/studio/m3-r-t.png" />
            <img src="@/assets/images/studio/m3-r-b.png" />
          </div>
          <div class="t1">更强的内容扩展性</div>
          <div class="t2">数字多媒体动画、特效手势互动、3D数字人</div>
        </div>
      </div>
    </div>
    <div class="solution">
      <div class="title">混空Studio使用教程</div>
      <div class="title-tips">一站式可视化编辑，轻松实现AR创作与开发</div>
      <div class="solution-content">
        <div class="title-tab">
          <div v-for="(item, index) in solutionBannerList" :key="index"
            :class="(solutionActiveIndex == index ? 'active' : '') + (index == 3 ? ' no-list' : '')"
            @click="changeSolution(index)">
            <img :src="solutionActiveIndex == index ? item.a_url : item.url" />
            <span>{{ item.title }}</span>
            <div v-if="solutionActiveIndex == index">
              <div v-for="(e, i) in item.content" :key="i">{{ e }}</div>
            </div>
            <div v-if="solutionActiveIndex == index" class="line"></div>
          </div>
        </div>
        <div>
          <div class="explanation-video" :style="{ opacity: solutionActiveIndex == index ? 1 : 0 }"
            v-for="(item, index) in solutionBannerList" :key="index">
            <img v-if="item.i_url" :src="item.i_url" />
            <video v-if="item.v_url" :src="item.v_url" autoplay loop muted playsinline webkit-playsinline></video>
          </div>
        </div>
      </div>
    </div>
    <div class="package">
      <div class="title deep">根据需求选择不同套餐</div>
      <div class="title-tips deep">使用我们全套XR开发工具，跨设备部署到每个项目</div>
      <div class="package-list">
        <div v-for="(item, index) in packageList" :key="index" :class="item.className">
          <div>
            <div class="package-name">{{ item.name }}</div>
            <div class="package-tips">{{ item.tips }}</div>
            <div class="package-btn" @click="toContact(item.btnText)">{{ item.btnText }}</div>
          </div>
          <div>
            <div v-for="(e, i) in item.content" :key="i">
              <span class="package-list-icon">{{ e.title }}</span>
              <span>{{ e.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us v-if="store.state.contactUsTop"></contact-us>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue'
import ContactUs from '@/components/ContactUs2.vue'
import router from '@/router';
import { asynchronousRetrieval } from '@/utils'
import store from '@/store';

const loadUrl: any = ref({})
let videoDom: any = null;
const roleTextList = ref(
  [
    {
      title: '构建AR大场景',
      tips: 'SAAS化部署，无缝开发沉浸式世界里的增强现实内容，所有这些都在您的网络浏览器中创建和部署。',
      url: require('@/assets/images/home/room-platform.png'),
      activeClass: 'room-bg'
    },
    {
      title: 'XR眼镜端',
      tips: '通过账号进行登陆并选择相应场景进入相关展示内容。根据现场虚与实的融合进行体验。',
      url: require('@/assets/images/home/ar-rokid.png'),
      activeClass: 'glass-bg'
    },
    {
      title: '移动端',
      tips: '游客模式使用手机或Pad直接进行空间互动体验。(设备支持ARkit/ARCore)。',
      url: loadUrl.value.web_platform,
      activeClass: 'web-bg'
    }
  ]
)
const activeRoleIndex = ref(0)
const solutionBannerList: any = ref(
  [
    {
      title: '采集地图',
      content: ['场地调研', '现场拍摄', '空间采集'],
      url: require('@/assets/images/studio/solution-l-icon1.png'),
      a_url: require('@/assets/images/studio/solution-l-icon1A.png'),
    },
    {
      title: '创建场景',
      content: ['设置互动区域', '上传内容素材', '虚拟空间装修', '支持小程序场景'],
      url: require('@/assets/images/studio/solution-l-icon2.png'),
      a_url: require('@/assets/images/studio/solution-l-icon2A.png'),
    },
    {
      title: '快速部署',
      content: ['生成账号权限', '打包标准应用', '安装应用程序'],
      url: require('@/assets/images/studio/solution-l-icon3.png'),
      a_url: require('@/assets/images/studio/solution-l-icon3A.png'),
    },
    {
      title: '虚实融合体验',
      content: ['【眼镜端体验】 接入支持AR/MR/VR眼镜', '【移动端体验】 支持微信小程序体验'],
      url: require('@/assets/images/studio/solution-l-icon4.png'),
      a_url: require('@/assets/images/studio/solution-l-icon4A.png'),
    }
  ]
)
const packageList = [
  {
    name: '个人体验版',
    tips: '适用于个人零基础搭建AR场景',
    btnText: '免费制作',
    className: 'l1',
    content: [
      {
        title: '场景数量',
        value: '3个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '1G'
      }
    ]
  },
  {
    name: '基础版',
    tips: '适用于中小型项目场景',
    btnText: '立即咨询',
    className: 'l2',
    content: [
      {
        title: '场景数量',
        value: '13个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '30G'
      }
    ]
  },
  {
    name: '专业版',
    tips: '适用于专业型项目场景',
    btnText: '立即咨询',
    className: 'l3',
    content: [
      {
        title: '场景数量',
        value: '20个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '小程序空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '60G'
      },
      {
        title: '一对一专业服务',
        value: ' '
      }
    ]
  },
  {
    name: '旗舰版',
    tips: '适用于综合型项目场景',
    btnText: '立即咨询',
    className: 'l4',
    content: [
      {
        title: '场景数量',
        value: '40个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '小程序空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '100G'
      },
      {
        title: '一对一专业服务',
        value: ' '
      }
    ]
  }
]
const solutionActiveIndex = ref(1)

let timer: any = null;
let lineStep = 1;

const toContact = (text: string) => {
  if (text == '免费制作') {
    window.scrollTo(0, 0)
    window.location.href = 'https://mixedspace.njyjxr.com/';
  } else {
    let contactUsTop: any = store.state.contactUsTop;
    contactUsTop = {
      bottom: 540 + 'rem'
    }
    store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
  }
}

const changeSolution = (index: number) => {
  solutionActiveIndex.value = index;
  tutorialCarousel()
}

const scrollEvent = () => {
  const screenRatio = (window.innerWidth / 1920);
  //m1
  const m1: any = document.querySelector('.advantage .m1');
  if (m1.getBoundingClientRect().top < window.innerHeight / 3 * 2) {
    (document.querySelector('.m1 .m1-img') as any).style.top = '33rem'
  }

  // m2
  const m2: any = document.querySelector('.advantage .m2');
  if (m2.getBoundingClientRect().top < window.innerHeight / 3 * 2) {
    (document.querySelector('.m2 .img1') as any).style.top = '19rem';
    (document.querySelector('.m2 .img2') as any).style.left = '94rem';
    (document.querySelector('.m2 .img3') as any).style.right = '72rem';
  }

  // m3
  const m3: any = document.querySelector('.advantage .m3');
  const m3Top = m3.getBoundingClientRect().top
  const m3_r_child = document.querySelectorAll('.m3-r img');
  if (m3Top < window.innerHeight && m3Top > 0) {
    const offsetValue = (60 * screenRatio) * (m3Top / window.innerHeight);
    m3_r_child.forEach((e: any, i: number) => {
      if (i % 2) {
        e.style.marginLeft = -offsetValue + 'px'
      } else {
        e.style.marginLeft = offsetValue + 'px'
      }
    })
  }
}

const tutorialCarousel = () => {
  lineStep = 1;
  const line: any = document.querySelector('.solution-content .active .line');
  line.style.width = (380 / 5) * lineStep + 'rem';
  clearInterval(timer)
  timer = setInterval(() => {
    if (lineStep > 5) {
      solutionActiveIndex.value += 1;
      if (solutionActiveIndex.value > 3) {
        solutionActiveIndex.value = 0
      }
      lineStep = 1;

    }
    nextTick(() => {
      const line: any = document.querySelector('.solution-content .active .line');
      line.style.width = (380 / 5) * lineStep + 'rem';
      lineStep++;
    })

  }, 1050)
}

watch(() => solutionActiveIndex.value, (newState) => {
  if (solutionBannerList.value[newState].v_url) {
    videoDom = document.querySelectorAll('.explanation-video')[newState]?.children[0];
    videoDom.pause();
    setTimeout(() => {
      videoDom.play();
    }, 200)
  }
})

onMounted(async () => {
  window.addEventListener('scroll', scrollEvent, false)
  tutorialCarousel()

  const studio_banner: any = await asynchronousRetrieval('webimg/video/studio-banner.webm');
  const web_platform: any = await asynchronousRetrieval('webimg/images/home/web-platform.png');
  const collect_maps: any = await asynchronousRetrieval('webimg/video/collect-maps.mp4');
  const creating_scenes: any = await asynchronousRetrieval('webimg/video/creating-scenes.mp4');
  const rapid_deployment: any = await asynchronousRetrieval('webimg/images/studio/rapid-deployment.png');
  const virtual_real_fusion: any = await asynchronousRetrieval('webimg/video/virtual-real-fusion.mp4');
  const glass_platform_bg: any = await asynchronousRetrieval('webimg/images/home/glass_platform_bg.png');
  loadUrl.value = { studio_banner, web_platform, collect_maps, creating_scenes, rapid_deployment, virtual_real_fusion, glass_platform_bg }

  roleTextList.value[2].url = web_platform
  solutionBannerList.value[0].v_url = collect_maps
  solutionBannerList.value[1].v_url = creating_scenes
  solutionBannerList.value[2].i_url = rapid_deployment
  solutionBannerList.value[3].v_url = virtual_real_fusion
})

onUnmounted(() => {
  clearInterval(timer)
  window.removeEventListener('scroll', scrollEvent, false)
})
</script>
<style scoped lang="less">
.title {
  font-weight: 600;
  font-size: 61rem;
  color: #FFFFFF;

  &.deep {
    color: #000;
  }
}

.title-tips {
  font-weight: 500;
  font-size: 32rem;
  color: #FFFFFF;
  margin-top: 24rem;
  margin-bottom: 47rem;

  &.deep {
    color: #000;
  }
}

.header-video {
  position: relative;
  width: 100%;
  height: 44.79vw;
  position: relative;
  background-size: 100% 100%;
  background: #348AD7;

  video {
    // width: 1920rem;
    height: 100%;
    object-fit: fill;
    position: absolute;
    right: 0;
    top: 0;
  }

  .video-text {
    width: 58.33vw;
    position: absolute;
    top: 14.73958vw;
    left: 50%;
    margin-left: -29.167vw;
    font-size: 1.0417vw;
    color: #FFFFFF;
    text-align: left;

    .logo-text {
      font-weight: 600;
      font-size: 4.167vw;
      color: #FFFFFF;
    }

    .link-room {
      font-size: 2.1354vw;
      color: #FFFFFF;
      margin-top: 1.0417vw;
    }

    .experience-now {
      width: 9.479vw;
      height: 3.3854vw;
      background: #fff;
      border-radius: 1.71875vw;
      margin-top: 3.75vw;
      padding: 0 0.36458vw 0 1.25vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: #0675FF;

      .experience-btn {
        width: 2.604vw;
        height: 2.604vw;
        background: url(~@/assets/images/next-icon2.png);
        background-size: 100% 100%;
      }
    }
  }
}

.role-wrap {
  // width: 1920rem;
  height: 968rem;
  background: #242424;
  padding-top: 116rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .role-scene {
    width: 1228rem;
    height: 524rem;
    font-size: 0;

    &>div {
      position: relative;
      width: 361rem;
      height: 524rem;
      background: #303030;
      border-radius: 24rem;
      border: 1px solid #979797;
      display: inline-block;
      margin-left: -55rem;
      padding-top: 62rem;
      box-sizing: border-box;
      padding-right: 55rem;
      vertical-align: middle;
      transition: all .5s;
      border: 8px solid #303030;
      cursor: pointer;

      &::after {
        content: '';
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border: 1px solid #979797;
        position: absolute;
        left: -8px;
        top: -8px;
        border-radius: 24rem;
        transition: none;
        z-index: 0;
      }

      .role-title {
        font-weight: 500;
        font-size: 32rem;
        color: #FFFFFF;
        text-align: left;
        padding-left: 47rem;
      }

      .role-title-tips {
        font-weight: 500;
        font-size: 16rem;
        color: rgba(255, 255, 255, 0.6);
        width: 210rem;
        margin-top: 24rem;
        margin-left: 47rem;
        margin-bottom: 70rem;
        text-align: left;
        text-align: justify;
      }

      .rokid {
        width: 240rem;
        margin-top: 30rem;
        height: auto;
      }

      img {
        width: 280rem;
        height: 238rem;
      }
    }

    .room-bg,
    .glass-bg,
    .web-bg {
      width: 562rem;
      background-image: url(~@/assets/images/home/room-platform-bg.png);
      background-size: 100% 100%;
      border: 8px solid #000;
      background-color: #303030;

      &>div {
        color: #fff;
      }
    }

    .glass-bg {
      background-image: url('@/assets/images/home/glass-platform-bg.png');
      background-size: 100% 100%;
    }

    .web-bg {
      width: 505rem !important;
      background-image: url(~@/assets/images/home/web-platform-bg.png);
      background-size: 100% 100%;
    }

    &>div:first-child {
      margin-left: 0;
    }

    &>div:last-child {
      width: 304rem;
      padding-right: 0;
    }
  }
}

.advantage {
  height: 1580rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #E9EFF6 100%);
  padding-top: 112rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  &>div {
    margin-bottom: 24rem;
  }

  .title {
    margin-bottom: -5rem;
  }

  .title-tips {
    margin-bottom: 52rem;
  }

  .m1 {
    margin-top: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      margin: 0 10rem;
    }

    &>div:first-child {
      width: 540rem;
      height: 344rem;
      background: #1F2A34;
      border-radius: 24rem;
      position: relative;
      font-weight: 500;
      padding: 230rem 120rem 0 41rem;
      box-sizing: border-box;
      overflow: hidden;

      img {
        width: 419rem;
        height: 277rem;
        position: absolute;
        // top: 33rem;
        left: 61rem;
        top: 500rem;
        transition: all 1s;
      }

      &>div {
        position: relative;
        z-index: 1;
        text-align: left;
      }

      &>div.t1 {
        font-size: 26rem;
        color: #FFFFFF;
      }

      &>div.t2 {
        font-size: 16rem;
        color: #C0C0C0;
        margin-top: 7rem;
      }

      &::after {
        content: '';
        width: 460rem;
        height: 183rem;
        background: linear-gradient(180deg, rgba(31, 42, 52, 0) 0%, rgba(31, 42, 52, 0.95) 50%, #1F2A34 100%);
        border-radius: 24rem;
        position: absolute;
        left: 29rem;
        bottom: 21rem;
      }
    }

    &>div:last-child {
      width: 380rem;
      height: 344rem;
      background: #FFFFFF;
      border-radius: 24rem;
      position: relative;
      padding: 230rem 0 0 39rem;
      box-sizing: border-box;
      font-weight: 500;
      text-align: left;

      img {
        width: 299rem;
        height: 154rem;
        position: absolute;
        top: 42rem;
        left: 39rem;
      }

      .t1 {
        font-size: 26rem;
        color: #000000;
        margin-bottom: 7rem;
      }

      .t2 {
        font-size: 16rem;
        color: #7F7F7F;
      }
    }
  }

  .m2 {
    width: 940rem;
    height: 416rem;
    background: url('@/assets/images/studio//m2-bg.png');
    background-size: 100% 100%;
    position: relative;
    padding: 319rem 0 0 41rem;
    font-weight: 500;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;

    .img1 {
      width: 676rem;
      height: 285rem;
      position: absolute;
      // top: 19rem;
      top: 500rem;
      left: 129rem;
      transition: all 1s;
    }

    .img2 {
      width: 95rem;
      height: 140rem;
      position: absolute;
      // left: 94rem;
      left: -300rem;
      top: 152rem;
      z-index: 1;
      transition: all 1s;
    }

    .img3 {
      position: absolute;
      width: 182rem;
      height: 170rem;
      top: 186rem;
      // right: 72rem;
      right: -300rem;
      transition: all 1s;
    }

    .t1 {
      font-size: 26rem;
      color: #FFFFFF;
      margin-bottom: 7rem;
    }

    .t2 {
      font-size: 16rem;
      color: #C0C0C0;
    }
  }

  .m3 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;

    &>div {
      width: 460rem;
      height: 314rem;
      margin: 0 10rem;
      padding: 217rem 0 0 41rem;
      box-sizing: border-box;
      font-weight: 500;

      .t1 {
        font-size: 26rem;
        margin-bottom: 7rem;
      }
    }

    &>div:first-child {
      position: relative;
      background: #FFFFFF;
      border-radius: 24rem;

      .t1 {
        color: #000000;
      }

      .t2 {
        font-size: 16rem;
        color: #7F7F7F;
      }
    }

    &>div:last-child {
      background: url(~@/assets/images/studio/m3-r-bg.png);
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;

      .t1 {
        color: #fff;
      }

      .t2 {
        font-size: 16rem;
        color: #C0C0C0;
      }
    }

    .m3-icons {
      width: 380rem;
      height: 110rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 40rem;
      top: 57rem;

      img {
        width: 110rem;
        height: 110rem;
      }
    }

    .m3-r {
      position: relative;
      position: absolute;
      top: 54rem;
      left: 0;

      img {
        width: 568rem;
        height: 52rem;
        display: inline-block;
        position: absolute;
      }

      &>img:first-child {
        top: 0;
        left: 16rem;
      }

      &>img:last-child {
        top: 68rem;
        left: -70rem;
      }
    }
  }
}

.solution {
  height: 945rem;
  background: #232323;
  padding-top: 112rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .solution-content {
    margin-top: 25rem;
    height: 484rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div:not(.title-tab) {
      position: relative;
      width: 710rem;
      height: 484rem;
      border-radius: 30rem;
      overflow: hidden;
      border: 2px solid #bbb;
      font-size: 0;
      box-sizing: border-box;

      &>div {
        width: 710rem;
        height: 484rem;
        position: absolute;
        left: 0;
        top: 0;
      }

      img {
        position: relative;
        width: calc(100% + 4rem);
        height: calc(100% + 4rem);
        left: -2rem;
        top: -2rem;
      }

      video {
        position: relative;
        width: calc(100% + 4rem);
        height: calc(100% + 4rem);
        object-fit: fill;
        left: -2rem;
        top: -2rem;
      }
    }

    &>div:first-child {
      width: 380rem;
      text-align: left;
      margin-right: 30rem;

      &>div {
        margin-top: 47rem;
        padding-left: 16rem;
        box-sizing: border-box;
        cursor: pointer;
        color: #7F7F7F;
      }

      .active {
        position: relative;
        padding-top: 24rem;
        background: #181818;
        box-shadow: inset 0px -2rem 0px 0px #363636;
        border-radius: 2rem;
        // height: 136rem;
        box-sizing: border-box;
        margin-top: 24rem;
        padding-bottom: 20rem;

        &>span {
          color: #fff;
        }

        &.no-list>div:not(.line) {
          padding-left: 38rem;
        }

        &.no-list>div:not(.line)>div::before {
          content: none;
        }

        &>div:not(.line) {
          position: relative;
          text-align: left;
          margin-top: 8rem;
          font-weight: 400;
          font-size: 16rem;
          line-height: 24rem;
          padding-left: 68rem;

          &>div {
            position: relative;
          }

          &>div::before {
            content: '';
            width: 6rem;
            height: 6rem;
            background-color: #7F7F7F;
            position: absolute;
            left: -15rem;
            top: 8rem;
            border-radius: 50%;
          }
        }

        .line {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          border-bottom: 2px solid;
          height: 0;
          border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(6, 117, 255, 1), rgba(6, 196, 255, 1)) 2 2;
        }
      }

      img {
        width: 34rem;
        height: 34rem;
        vertical-align: middle;
      }

      span {
        font-weight: 500;
        font-size: 24rem;
        color: #C1C1C1;
        vertical-align: middle;
        margin-left: 14rem;
      }
    }
  }
}

.package {
  height: 1046rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #E9EFF6 100%);
  padding-top: 112rem;
  box-sizing: border-box;

  .package-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 109rem;

    .package-list-icon {
      padding-left: 22rem;
      position: relative;

      &::before {
        content: '';
        background-image: url(~@/assets/images/studio/list-icon.png);
        background-size: 100% 100%;
        width: 17rem;
        height: 12rem;
        position: absolute;
        left: 0;
        top: 5rem;
      }
    }

    .l2>div:first-child {
      background: linear-gradient(180deg, #9FDEFF 0%, #FFFFFF 100%);
    }

    .l3>div:first-child {
      background: linear-gradient(180deg, #F0EBFD 0%, #FFFFFF 100%);
    }

    .l4>div:first-child {
      background: linear-gradient(180deg, #1E84FF 0%, #2E1B62 100%);

      &>div {
        color: #fff;
      }

      .package-btn {
        background: #FFFFFF;
        color: #0675FF;
      }
    }

    &>div {
      margin: 0 14rem;
      width: 262rem;
      height: 548rem;
      background: linear-gradient(180deg, #F2FAFB 0%, #FFFFFF 100%);
      box-shadow: 0px 4rem 15rem 0px rgba(206, 225, 231, 0.5);
      border-radius: 32rem;
      border: 1px solid #FFFFFF;
      overflow: hidden;

      &>div:first-child {
        width: 262rem;
        height: 210rem;
        background: linear-gradient(180deg, #F2FAFB 0%, #FFFFFF 100%);
        padding-top: 36rem;
        box-sizing: border-box;
        text-align: center;
      }

      &>div:last-child {
        padding: 46rem 24rem 0;
        font-weight: 400;
        font-size: 14rem;
        color: #000000;

        &>div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 18rem;
        }
      }

      .package-name {
        font-weight: 600;
        font-size: 24rem;
        color: #000000;
        margin-bottom: 17rem;
      }

      .package-tips {
        font-weight: 400;
        font-size: 14rem;
        color: #000000;
        margin-bottom: 29rem;
      }

      .package-btn {
        width: 142rem;
        height: 46rem;
        line-height: 46rem;
        background: #0675FF;
        border-radius: 33rem;
        margin-left: 60rem;
        font-weight: 500;
        font-size: 20rem;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}
</style>