<template>
  <div class="vr-simulation">
    <sub-title title-text="应用场景"></sub-title>
    <div class="yxhd">
      <main>
        <div class="item" v-for="item in modelTypes['virtual-vr']" :key="item.header">
          <img :src="item.image" alt="">
          <div class="content">
            <div v-for="(e, i) in item.remarkList" :key="i">{{ e }}</div>
          </div>
          <div class="content-title">{{ item.header }}</div>
        </div>
        <div class="item hzzx" @click="handleEvent(2)">
          <img src="@/assets/images/ar-images/hezx.png" alt="">
        </div>
      </main>
    </div>
    <div class="wmdys">
      <sub-title mode-class="black-style" title-text="我们的优势"></sub-title>
      <div class="wmdys-content">
        <img :src="wmdysDataList[wmdysActiveIndex].url" />
        <div class="wmdys-content-list">
          <div v-for="(item, index) in wmdysDataList" :key="index" :class="wmdysActiveIndex == index ? 'active' : ''"
            @click="wmdysActiveIndex = index">
            <div class="title">{{ item.title }}</div>
            <div class="list-box">
              <div v-for="(e, i) in item.list" :key="i">{{ e }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.xnfz"></example-introduction>
    <step-process :step-title="['沉浸式体验', '一站式部署流程']" step-type="step1"></step-process>
    <div class="counselling-btn">
      <div class="btn" @click="handleEvent(1)">合作咨询</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { modelTypes } from '@/config';
import SubTitle from '@/components/SubTitle.vue';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue';
import StepProcess from '@/components/common/StepProcess.vue';
import { exampleDatas } from '@/config/index'
import store from '@/store';

const wmdysDataList = [
  {
    title: '技术优势',
    list: ['· 全面的数据获取能力', '· 高效率高精度', '· 强大的适应性与稳定性', '· 高沉浸式与互动性'],
    url: require('@/assets/images/ar-images/wmdys-vr2.png')
  },
  {
    title: '硬件优势',
    list: ['· 与XR领域头部硬件商达成长期战略合作', '· 根据不同的场景需求，匹配最佳硬件方案'],
    url: require('@/assets/images/ar-images/wmdys-vr3.png')
  },
  {
    title: '自研软件',
    list: ['· 运用自研的元宇宙虚实融合应用平台-混空Studio，低成本、高效率快速创作搭建虚实融合场景。'],
    url: require('@/assets/images/ar-images/wmdys-vr1.png')
  }
]
const wmdysActiveIndex = ref(0)

const handleEvent = (type: number) => {
  let contactUsTop: any = store.state.contactUsTop;
  if (type == 1) {
    contactUsTop = {
      bottom: 457 + 'rem'
    }
  } else {
    contactUsTop = {
      top: 1625 + 'rem'
    }
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

.vr-simulation {
  background-color: #E1EFFF;
  padding-top: 116rem;

  .yxhd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    height: 1110rem;
    background: #E1EFFF;
    margin: 64rem 0 40rem;

    main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1119rem;

      .item {
        position: relative;
        width: 359rem;
        height: 334rem;
        background: #FFFFFF;
        border-radius: 20rem;
        margin-bottom: 36rem;
        overflow: hidden;
        font-size: 0;

        &.hzzx img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        img {
          width: 359rem;
          height: 231rem;
        }

        .content {
          box-sizing: border-box;
          padding: 21rem 22rem 0;
          text-align: left;
          font-weight: 400;
          font-size: 14rem;
          color: #5C5C5C;
          line-height: 24rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;

          &>div {
            position: relative;
            padding-left: 16rem;
            line-height: 24rem;
            margin-bottom: 12rem;
            box-sizing: border-box;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 8rem;
              width: 8rem;
              height: 8rem;
              background-color: #5C5C5C;
              border-radius: 100%;
            }
          }

          &>div:nth-child(2n) {
            max-width: 50%;
            width: 116rem;
          }

          &>div:nth-child(2n+1) {
            min-width: 50%;
          }
        }

        .content-title {
          position: absolute;
          top: 187rem;
          width: 100%;
          height: 44rem;
          line-height: 44rem;
          font-weight: 500;
          font-size: 20rem;
          color: #FFFFFF;
          text-align: center;
          background-color: rgba(46, 118, 255, 0.70);
        }
      }
    }
  }

  .wmdys {
    width: 1920rem;
    height: 905rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;

    .wmdys-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 64rem;

      img {
        width: 800rem;
        height: 360rem;
        border-radius: 20rem;
      }

      .wmdys-content-list {
        width: 800rem;
        height: 170rem;
        margin-top: 26rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &>div {
          width: 259rem;
          height: 170rem;
          border-radius: 12rem;
          filter: blur(0px);
          color: #fff;
          cursor: pointer;
          padding: 20rem 20rem 0;
          box-sizing: border-box;
          text-align: left;

          .title {
            font-weight: 500;
            font-size: 20rem;
            color: #FFFFFF;
            line-height: 28rem;
          }

          .list-box {
            font-weight: 400;
            font-size: 14rem;
            line-height: 24rem;
            margin-top: 10rem;

            &>div {
              text-align: justify;
            }
          }

          &.active {
            background: linear-gradient(180deg, rgba(251, 251, 251, 0.24) 0%, rgba(147, 147, 147, 0.24) 100%);
          }
        }
      }
    }
  }
}
</style>