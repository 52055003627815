<template>
  <div class="navigation-guide">
    <div class="yycj">
      <sub-title title-text="应用场景"></sub-title>
      <div class="yycj-content">
        <main>
          <div class="item" v-for="item in modelTypes['navigation-guide']" :key="item.header">
            <img :src="item.image" alt="">
            <div class="content">{{ item.remarkList[0] }}</div>
            <div class="content-title">{{ item.header }}</div>
          </div>
        </main>
      </div>
    </div>
    <div class="wmdys">
      <sub-title mode-class="black-style" title-text="我们的优势"></sub-title>
      <div class="wmdys-content">
        <div class="wmdys-content-list">
          <div v-for="(item, index) in wmdysDataList" :key="index">
            <img :src="item.url" />
            <div class="title">{{ item.title }}</div>
            <div class="list-box">
              <div>{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.ardh"></example-introduction>
    <step-process :step-title="['沉浸式体验', '一站式部署流程']" step-type="step1"></step-process>
    <div class="counselling-btn">
      <div class="btn" @click="handleEvent">合作咨询</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SubTitle from '@/components/SubTitle.vue';
import { modelTypes } from '@/config';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue';
import StepProcess from '@/components/common/StepProcess.vue';
import { exampleDatas } from '@/config/index'
import store from '@/store';

const wmdysDataList = [
  {
    title: '沉浸式体验',
    content: 'AR导航导览通过创建虚拟场景和互动元素，极大地提升了游览的乐趣和参与度。',
    url: require('@/assets/images/ar-images/icon/cjs-icon.png')
  },
  {
    title: '精准导航',
    content: 'AR导航导览能够为用户提供厘米级的定位精度，确保用户不会迷路或错过重要景点。',
    url: require('@/assets/images/ar-images/icon/jzdh-icon.png')
  },
  {
    title: '个性化服务',
    content: 'AR导航导览支持定制化开发，如选择游览路线、景点介绍和互动体验等，使旅游过程更加灵活多样。',
    url: require('@/assets/images/ar-images/icon/gxhfw-icon.png')
  },
  {
    title: '丰富的互动元素',
    content: 'AR导航导览中融入如寻宝、解谜等趣味游戏，及AR弹幕等社交互动功能，增加游览的趣味性和挑战性。',
    url: require('@/assets/images/ar-images/icon/hdys-icon.png')
  },
  {
    title: '跨平台支持',
    content: 'AR导航导览系统支持多种设备平台，如iOS、Android等，确保用户在不同设备上都能获得一致的使用体验。',
    url: require('@/assets/images/ar-images/icon/kpt-icon.png')
  }
]

const handleEvent = () => {
  let contactUsTop: any = store.state.contactUsTop;
  contactUsTop = {
    bottom: 457 + 'rem'
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>
<style scoped lang="less">
.navigation-guide {
  .yycj {
    width: 1920rem;
    height: 1062rem;
    background: #E1EFFF;
    padding-top: 117rem;
    box-sizing: border-box;

    .yycj-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 100vw;
      height: 762rem;
      background: #E1EFFF;
      margin: 64rem 0 40rem;

      main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1119rem;

        .item {
          position: relative;
          width: 359rem;
          height: 334rem;
          background: #FFFFFF;
          border-radius: 20rem;
          margin-bottom: 46rem;
          overflow: hidden;
          font-size: 0;

          img {
            width: 359rem;
            height: 231rem;
          }

          .content {
            box-sizing: border-box;
            padding: 14rem 22rem 0;
            text-align: justify;
            font-weight: 400;
            font-size: 14rem;
            color: #5C5C5C;
            line-height: 24rem;

            h3 {
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              margin-bottom: 20rem;
              text-align: left;
              box-sizing: border-box;
              padding-left: 1rem;
            }


            ul {
              padding-left: 20rem;

              li {
                font-size: 14rem;
                color: #5C5C5C;
                text-align: left;
                line-height: 24rem;
              }
            }
          }

          .content-title {
            position: absolute;
            top: 187rem;
            width: 100%;
            height: 44rem;
            line-height: 44rem;
            font-weight: 500;
            font-size: 20rem;
            color: #FFFFFF;
            text-align: center;
            background-color: rgba(46, 118, 255, 0.70);
          }
        }
      }
    }
  }

  .wmdys {
    width: 1920rem;
    height: 765rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;

    .wmdys-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 64rem;

      .wmdys-content-list {
        width: 1120rem;
        height: 414rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &>div {
          width: 216rem;
          height: 414rem;
          border-radius: 12rem;
          filter: blur(0px);
          color: #fff;
          cursor: pointer;
          padding: 52rem 32rem 0;
          box-sizing: border-box;
          text-align: left;
          font-weight: 400;
          font-size: 14rem;
          color: #414141;
          line-height: 24rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-image: url(~@/assets/images/ar-images/dh-wmdys.png);
          background-size: 100% 100%;

          img {
            width: 60rem;
            height: 60rem;
            margin-bottom: 36rem;
          }

          .title {
            font-weight: 500;
            font-size: 20rem;
            color: #414141;
            line-height: 32rem;
            text-align: center;
          }

          .list-box {
            font-weight: 400;
            font-size: 14rem;
            line-height: 24rem;
            margin-top: 16rem;

            &>div {
              text-align: justify;
            }
          }

          &.active {
            background: linear-gradient(180deg, rgba(251, 251, 251, 0.24) 0%, rgba(147, 147, 147, 0.24) 100%);
          }
        }
      }
    }
  }
}
</style>