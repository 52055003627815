<template>
  <div class="sljs-box">
    <sub-title title-text="实例介绍"></sub-title>
    <div class="sljs" :style="{ paddingBottom: exampleData.itemList ? '120rem' : 0 }">
      <div class="top-con">
        <div v-for="(item, index) in exampleData.example" :key="index">
          <div class="img-box">
            <img :src="item.url" @mousemove="showIndex = index" />
            <img v-if="showIndex == index" :src="loadUrl[showKeyMap[exampleData.keyName][showIndex]]?.url"
              @mouseleave="showIndex = -1" />
          </div>
          <div class="desc">{{ item.title }}</div>
          <div class="foooter">
            <img src="@/assets/images/ar-images/nav-icon.png" alt="">
            <span>{{ item.address }}</span>
          </div>
        </div>
      </div>
      <div class="sub-style" v-if="exampleData.itemList"><sub-title title-text="合作方式"></sub-title></div>
      <div class="bottom-con" v-if="exampleData.itemList">
        <div class="l-con">
          <div :class="item.className + ' ' + (index == activeItemIndex ? 'active' : '')"
            v-for="(item, index) in exampleData.itemList" :key="index" @click="activeItemIndex = index">
            <div class="title">{{ item.title }}</div>
            <div class="remark">{{ item.remark }}</div>
          </div>
        </div>
        <div class="r-con">
          <img :src="exampleData.itemList[activeItemIndex].url" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import SubTitle from '@/components/SubTitle.vue';
import { asynchronousRetrieval } from '@/utils'

const props = defineProps({
  exampleData: {
    default: null,
    type: Object
  }
})

const activeItemIndex = ref(0)
const loadUrl: any = ref({})
const showIndex = ref(-1);
const showKeyMap: any = {
  kjsj: ['qyzt', 'scdkj'],
  arWechat: ['mlc', 'qtxct', 'bskl', 'csyx'],
  xnfz: ['sxcz', 'xnzp', 'cjsjc', 'hxkp'],
  xnbwg: ['bwgjs', 'bwgsd', 'bwgbj', 'bwgzj'],
  ardh: ['mlc', 'ckzc', 'jqdh', 'zhyq'],
  yyzzt: ['ddzs', 'yyzzt', 'jswysg', 'sxcz']
};

const loadGif = async (gifKey: string) => {
  const currentTimeStamp = new Date().getTime();
  if (!loadUrl.value[showKeyMap[gifKey][showIndex.value]] || (currentTimeStamp - loadUrl.value[showKeyMap[gifKey][showIndex.value]].timeStamp) > 10000) {
    const lastLoadObj = await asynchronousRetrieval(`webimg/gif/${showKeyMap[gifKey][showIndex.value]}.gif`);
    loadUrl.value[showKeyMap[gifKey][showIndex.value]] = {
      url: lastLoadObj,
      timeStamp: currentTimeStamp
    };
  }
}

onMounted(() => {
  loadGif(props.exampleData.keyName)
})

watch(() => showIndex.value, (newState) => {
  if (newState != -1) {
    loadGif(props.exampleData.keyName)
  }
})



</script>
<style scoped lang="less">
.sljs-box {
  padding-top: 120rem;
  background: #FFFFFF;

  .sljs {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 100vw;
    overflow: hidden;
    // padding-bottom: 120rem;
    margin-top: 64rem;

    .top-head {
      position: relative;
      margin: 120rem 0 64rem 0;
      font-weight: 500;
      font-size: 32rem;
      color: #414141;

      &::after {
        content: '';
        width: 100%;
        height: 13rem;
        background: rgba(35, 117, 255, 0.2);
        position: absolute;
        left: 0;
        bottom: 3rem;
      }

    }

    .top-con {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 1120rem;

      &>div {
        position: relative;
        width: 552rem;
        height: 309rem;
        margin-bottom: 154rem;

        &>.img-box {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 20rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }

          &>img:first-child {
            position: absolute;
            left: 0;
            top: 0;
          }

          &>img:last-child {
            position: relative;
            z-index: 1;
          }
        }

        .desc {
          position: absolute;
          bottom: -66rem;
          left: 0;
          font-weight: 500;
          font-size: 20rem;
          color: #414141;
          margin-bottom: 17rem;
          width: 100%;
          text-align: center;
        }

        .foooter {
          position: absolute;
          bottom: -90rem;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          img {
            width: 19rem;
            height: 25rem;
            margin-right: 10rem;
          }

          span {
            font-weight: 500;
            font-size: 18rem;
            color: #3D566C;
            transform: translateY(-1rem);
          }
        }
      }
    }

    .bto-head {
      position: relative;
      font-weight: 500;
      font-size: 32rem;
      color: #414141;
      margin-top: 210rem;

      &::after {
        content: '';
        width: 100%;
        height: 13rem;
        background: rgba(35, 117, 255, 0.2);
        position: absolute;
        left: 0;
        bottom: 3rem;
      }

    }

    .sub-style {
      margin-top: 60rem;
    }

    .bottom-con {
      display: flex;
      align-items: flex-start;
      margin-top: 64rem;
      color: #5C5C5C;

      .l-con,
      .r-con {

        .one,
        .two {
          text-align: left;
          width: 469rem;
          height: 140rem;
          background: #FFFFFF;
          border-radius: 12rem;
          box-sizing: border-box;
          padding: 30rem 32rem;
          cursor: pointer;
          font-weight: 400;
          font-size: 14rem;
          color: #5C5C5C;
          line-height: 20rem;

          .title {
            font-weight: 500;
            font-size: 20rem;
            color: #414141;
            line-height: 28rem;
            margin-bottom: 12rem;
          }
        }

        .one {
          // transform: translateY(-63rem);
        }

        .two {

          transform: translateY(24rem);
        }

        .one.active,
        .two.active {
          background: linear-gradient(180deg, #FBFBFB 0%, #F7F7F7 100%);
        }
      }

      .r-con {
        width: 640rem;
        height: 360rem;
        border-radius: 20rem;
        margin-left: 22rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>