<template>
    <div class="head-card">
        <header>{{ typeList[activeSolutionIndex]?.header }}</header>
        <main>{{ typeList[activeSolutionIndex]?.content }}</main>
        <div class="btn" @click="handleEvent">合作咨询</div>
    </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, onUnmounted, watch } from 'vue'
import { typeList } from '@/config';
import store from '@/store';
const props = defineProps({
    activeSolutionIndex: {
        default: 0,
        type: Number
    }
})

const handleEvent = () => {
    let contactUsTop: any = store.state.contactUsTop;
    contactUsTop = {
        top: 166 + 'rem'
    }
    store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>

<style scoped lang="less">
.head-card {
    width: 28.958vw;
    height: 25.52vw;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 1.5625vw;
    backdrop-filter: blur(10rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-right: 270rem;
    transform: translateX(65%);

    header {
        width: 20.625vw;
        font-weight: bold;
        font-size: 2.3958vw;
        color: #FFFFFF;
        line-height: 3.3854vw;
        text-align: left;
        margin-top: 3.333vw;
        margin-bottom: 1.667vw;
    }

    main {
        width: 20.625vw;
        font-weight: 500;
        font-size: 0.833vw;
        color: #FFFFFF;
        line-height: 1.667vw;
        text-align: justify;
    }

    .btn {
        position: absolute;
        width: 7.3958vw;
        height: 2.3958vw;
        background: #0675FF;
        border-radius: 1.71875vw;
        font-weight: 500;
        font-size: 1.0417vw;
        color: #FFFFFF;
        line-height: 2.3958vw;
        text-align: center;
        left: 4.0625vw;
        bottom: 3.0208vw;
        cursor: pointer;
    }
}
</style>