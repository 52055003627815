<template>
  <div>
    <div class="header-video">
      <video :src="loadUrl.home_banner_mp4" autoplay loop muted playsinline webkit-playsinline></video>
      <div class="video-text">
        <div class="logo-text"></div>
        <div class="link-room">打开「眼界」链接全新数字空间</div>
        <div class="text-tips">提供大空间数实融合新方案，通过灵活的采集方案、专业的空间定位及完善的后台工具，为众多行业进行AR数字化赋能。</div>
        <a class="experience-now" href="https://mixedspace.njyjxr.com">
          <span>立即体验</span>
          <div class="experience-btn"></div>
        </a>
      </div>
      <div class="video-mask"></div>
    </div>
    <div class="core-advantages">
      <img :src="loadUrl.core_advantages_bg" />
      <div>
        <div class="core-title">混空studio核心优势</div>
        <div>满足复杂场景设计需求</div>
        <div class="core-scene">
          <div class="studio-video">
            <video :src="loadUrl.studio_mp4" autoplay loop muted poster="@/assets/images/home/studio-mobile.jpg" playsinline webkit-playsinline></video>
          </div>
          <div class="cost-box">
            <div style="background-color: #fff;" class="cost-base">
              <div class="title">成本低</div>
              <div class="cost-wrap">
                <div v-for="(item, index) in costList" :key="index">
                  <div>
                    <div v-for="(e, i) in item" :key="i">{{ e }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="high-precision">
              <div class="title">高精度</div>
              <div class="precision-text">
                <div>自研高精度空间定位算法</div>
                <div>厘米级视觉定位</div>
                <div>空间导航精准</div>
                <div>支持大空间定位</div>
              </div>
              <div class="high-precision-image"></div>
            </div>
          </div>
          <div class="easy-operate">
            <div class="title">易操作</div>
            <div class="easy-operate-image">
              <img :src="loadUrl.ycaozuo_gif" />
            </div>
            <div>
              <div>自研混空studio编辑工具</div>
              <div>3D场景地图，可视化交互“拖拉拽”操作</div>
              <div>所“建”即所得</div>
            </div>
          </div>
          <div class="arrange">
            <div class="title">部署快</div>
            <div class="arrange-icon">
              <div v-for="item in arrangeList" :key="item">
                <img :src="item" />
              </div>
            </div>
            <div>
              <div>仅需四步，快速部署</div>
              <div>支持快速导入模型、音效和互动元素</div>
              <div>无需安装，在网页端即可完成空间创作</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fusion">
      <div class="fusion-bg" v-if="!store.state.zoomScreened">
        <video :src="loadUrl.entertainment_mp4" autoplay loop muted playsinline webkit-playsinline></video>
      </div>
      <div class="fusion-content" :style="{ opacity: store.state.zoomScreened ? 1 : 0 }">
        <div class="fusion-title">数实融合 虚实互生</div>
        <div class="fusion-tips">共同推动产业创新融合发展</div>
        <div class="fusion-window">
          <video v-for="(item, index) in metaversePlatformList" :style="{ opacity: activeFusionIndex == index ? 1 : 0 }"
            :src="item.url" :key="index" autoplay loop muted playsinline webkit-playsinline></video>
        </div>
        <div class="fusion-list-info">
          <div v-for="(item, index) in metaversePlatformList" :key="item.type"
            :class="activeFusionIndex == index ? 'active' : ''" @click="activeFusionIndex = index; viewTime = 1">
            <div class="metaverse-title">{{ item.title }}</div>
            <div class="metaverse-line">
              <div></div>
            </div>
            <div class="metaverse-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-project">
      <div class="show-project-title">跨设备部署全场景展示每个项目</div>
      <div class="show-project-tips">文娱景区、剧场演绎、博物馆/文化馆、IP展览、商场、企业展厅</div>
      <div class="show-project-tips">大型活动、品牌营销互动、广告策划、空间设计、教育培训、工业培训等。</div>
      <banner-view :banner-data="projectTypeList"></banner-view>
    </div>
    <div class="partner-wrap">
      <img :src="loadUrl.partner_bg" />
      <div>
        <div class="serve-customers">服务客户2000+</div>
        <div class="serve-tips">助力AR创新更多可能</div>
        <div class="serve-btn" @click="handleEvent">立即咨询</div>
      </div>
      <div class="partner-list partner1"></div>
      <div class="partner-list partner2"></div>
      <div class="partner-list partner3"><img :src="loadUrl.partner3" /></div>
      <div class="partner-list partner4"></div>
      <div class="partner-list partner5"><img :src="loadUrl.bg_mask" /></div>
    </div>
    <!-- <div class="bg-video">
      <video :src="loadUrl.particles_moment_mp4" autoplay loop muted playsinline webkit-playsinline></video>
      <div class="contact-us">
        <div class="title">成功提交信息，可享专业客服一对一支持</div>
        <div class="form-wrap">
          <el-input class="form-input" :class="checkEmpty.companyName ? 'error-cn' : ''"
            v-model="contactInfo.companyName" placeholder="公司名称" />
          <el-input class="form-input" :class="checkEmpty.name ? 'error-n' : ''" v-model="contactInfo.name"
            placeholder="姓名" />
          <el-input class="form-input" :class="showError || checkEmpty.phone ? 'error-text' : ''"
            v-model="contactInfo.phone" placeholder="电话" />
          <el-select placeholder="所属行业" :class="checkEmpty.industry ? 'error-i' : ''" v-model="contactInfo.industry"
            class="select-default" popper-class="select-option">
            <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="submit-btn" @click="submitEvent">提交信息</div>
      </div>
      <div class="contact-service" v-if="showCustomerState == 2">
        <div class="closed" @click="showCustomerState = 0"></div>
        <div class="title">联系我们的团队</div>
        <div class="title-tips">你可以通过以下几种方式联系我们，我们将为您提供专业的解答</div>
        <div class="service-list">
          <div style="padding: 29rem 0;">
            <img src="@/assets/images/home/customer.jpg" />
            <span>扫码添加客服微信</span>
          </div>
          <div>
            <div class="fontBold">购买咨询</div>
            <div class="fontBold">400-900-6269</div>
            <div class="fontNormal">工作日：9:30-18:30（北京时间）</div>
          </div>
          <div>
            <div class="fontBold">联系邮箱</div>
            <div class="fontBold">yang.he@njyjxr.com</div>
            <div class="fontNormal">48小时之内回复</div>
          </div>
        </div>
      </div>
      <contact-us :changeState="changeState" :state-value="showCustomerState"></contact-us>
    </div> -->
    <contact-us v-if="store.state.contactUsTop"></contact-us>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, watch, onUnmounted } from 'vue'
import BannerView from '@/components/BannerView.vue';
import ContactUs from '@/components/ContactUs2.vue'
import { useStore } from 'vuex'
import { addConsult } from '@/api'
import { asynchronousRetrieval } from '@/utils'

const loadUrl: any = ref({})
const costList = [['降低', '50%', '技术开发成本'], ['增加', '200%', '投资回报率'], ['节省', '80%', '部署时间'], ['综合提效', '300%', '创作落地']]
const arrangeList = [require('@/assets/images/home/list-icon.png'), require('@/assets/images/home/audio-bg-icon.png'), require('@/assets/images/home/service-bg-icon.png'), require('@/assets/images/home/blank-bg-icon.png'), require('@/assets/images/home/data-bg-icon.png'), require('@/assets/images/home/wifi-bg-icon.png'), require('@/assets/images/home/phone-bg-icon.png')]
const metaversePlatformList: any = ref(
  [
    {
      title: '文娱元宇宙',
      type: 1,
      content: '景区导览、遗迹重现、虚拟博物馆/文化馆/艺术馆、商业空间、IP展览、儿童游乐项目体验、企业展厅、营销互动、大型活动、虚拟数字人',
    },
    {
      title: '工业元宇宙',
      type: 2,
      content: '远程协助、数字孪生、点巡检、虚拟装配、维修维护、工厂漫游、技能培训、设计评审',
    },
    {
      title: '教育元宇宙',
      type: 3,
      content: '基础教育/高等教育/职业教育教学、播控、创新实验室建设、创新课堂建设、企业培训',
    }
  ]
)
const projectTypeList: any = ref(
  [
    {
      title: '微信小程序',
      type: 0,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 198,
      key: 'wechat',
      diff: 7,
      imgs: [
        {
          url: loadUrl.value.wechat_gif,
          class: 'wechat-bg',
          size: [-356, -321]
        },
        {
          url: require('@/assets/images/home/wechat-icon.png'),
          class: 'wechat-icon',
          size: [-200, -202]
        },
        {
          url: require('@/assets/images/home/wechat-icon2.png'),
          class: 'wechat-icon2',
          size: [-60, 190]
        }
      ]
    },
    {
      title: 'VR端',
      type: 1,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'VR',
      diff: 67,
      imgs: [
        {
          url: loadUrl.value.VR_gif,
          class: 'room-bg',
          size: [-356, -321]
        },
        {
          url: require('@/assets/images/home/rokid.png'),
          class: 'rokid',
          size: [-250, 50]
        }
      ]
    },
    {
      title: 'AR端',
      type: 2,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'AR',
      diff: 0,
      imgs: [
        {
          url: loadUrl.value.AR_gif,
          class: 'ar-bg',
          size: [-356, -321]
        },
        {
          url: require('@/assets/images/home/ar-rokid.png'),
          class: 'ar-rokid',
          size: [-288, 8]
        }
      ]
    },
    {
      title: 'web端',
      type: 3,
      content: '高效创作XR/3D/数字人/虚拟展厅等元宇宙内容助力品牌/营销',
      frameNum: 108,
      key: 'web',
      diff: 5,
      imgs: [
        {
          url: loadUrl.value.web_gif,
          class: 'web-bg',
          size: [-320, -268]
        }
      ]
    },
  ]
)
const activeFusionIndex = ref(0)
const showCustomerState = ref(0)
const store = useStore()
const viewTime = ref(0)
let timer: any = null;
const contactInfo: any = reactive({
  companyName: '',
  name: '',
  phone: '',
  industry: null
})
const industryList = [
  {
    name: '文娱',
    value: 1,
  },
  {
    name: '工业',
    value: 2,
  },
  {
    name: '教育',
    value: 3,
  },
  {
    name: '其他',
    value: 4,
  }
]
const fusionVideo: any = ref([20.966667, 18.552993, 16.068005])
const showError = ref(false)
const checkEmpty: any = ref({})


const handleEvent = () => {
  let contactUsTop: any = store.state.contactUsTop;
  contactUsTop = {
    bottom: 628 + 'rem'
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}

const submitEvent = () => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  Object.keys(contactInfo).forEach((k) => {
    checkEmpty.value[k] = !contactInfo[k]
  })
  if (!reg.test(contactInfo.phone.replace(/\s+/g, "")) || !contactInfo.phone) {
    showError.value = true;
    return
  } else if (!contactInfo.companyName || !contactInfo.name || !contactInfo.industry) {
    return
  }
  addConsult({ ...contactInfo }).then((res: any) => {
    showCustomerState.value = 2;
    contactInfo.companyName = ''
    contactInfo.name = ''
    contactInfo.phone = ''
    contactInfo.industry = null
    showError.value = false;
    checkEmpty.value = {}
  })
}

const changeState = (val: number) => {
  showCustomerState.value = val;
}

const scrollEvent = () => {
  // 执行的具体操作
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  (document.querySelector('.header') as any).style.transition = 'all 1s'
  const screenRatio = (window.innerWidth / 1920);


  // 虚实融合
  const fusionTop: any = (document.querySelector('.fusion') as any).getBoundingClientRect().top;
  const videoMask: any = document.querySelector('.video-mask');
  if (fusionTop <= 100 * screenRatio && !store.state.zoomScreened) {
    store.state.zoomScreened = true;
    const fusionWindow: any = document.querySelector('.fusion-window');
    if (fusionWindow) {
      fusionWindow.style.scale = 1;
      fusionWindow.style.opacity = 1;
      openTimer()
    }
  } else if (fusionTop > 100 * screenRatio) {
    openTimer()
  }

  if (scrollTop > 30) {
    videoMask.style.height = '50%';
  } else {
    videoMask.style.height = 0;
  }

  // 部署模块动效
  const arrangeChild: any = document.querySelectorAll('.arrange-icon>div');
  const arrangeTop: any = (document.querySelector('.arrange-icon') as any).getBoundingClientRect().top;
  if (arrangeTop > 0 && arrangeTop < window.innerHeight) {
    const offsetValue = (40 * screenRatio) * (arrangeTop / window.innerHeight);
    arrangeChild.forEach((ele: any, i: number) => {
      if (i < 3) {
        ele.style.left = offsetValue + 'px'
      } else {
        ele.style.right = offsetValue + 'px'
      }
    })
  }

  // 服务客户动效
  const partnerList: any = document.querySelectorAll('.partner-list:not(.partner5)');
  const partnerItem = (document.querySelector('.partner-wrap') as any).getBoundingClientRect();
  const partnerTop: any = partnerItem.top;
  if ((partnerTop > 0 && partnerTop < window.innerHeight) || partnerTop > -(partnerItem.height)) {
    const offsetValue = (100 * screenRatio) * (partnerTop / window.innerHeight);
    partnerList.forEach((ele: any, i: number) => {
      if (i % 2) {
        ele.style.marginLeft = -offsetValue + 'px'
      } else {
        ele.style.marginLeft = offsetValue + 'px'
      }
    })
  }

  // 成本模块动效
  const costBase: any = document.querySelector('.cost-base');
  const costBaseTop: any = costBase.getBoundingClientRect().top;
  if (costBaseTop > window.innerHeight) {
    costBase.style.top = '60rem'
  } else {
    costBase.style.top = Math.max(60 - (window.innerHeight - costBaseTop), 0) + 'rem'
  }

  // 高精度
  const highPrecision: any = document.querySelector('.high-precision');
  const highPrecisionTop: any = highPrecision.getBoundingClientRect().top;
  if (highPrecisionTop < window.innerHeight - 300) {
    (document.querySelector('.high-precision-image') as any).style.left = '31rem'
  }
  if (highPrecisionTop > window.innerHeight) {
    highPrecision.style.top = '80rem'
  } else {
    highPrecision.style.top = Math.max(80 - (window.innerHeight - highPrecisionTop), 0) + 'rem'
  }

  // 易操作
  const easyOperate: any = document.querySelector('.easy-operate');
  const easyOperateTop: any = easyOperate.getBoundingClientRect().top;
  if (easyOperateTop < window.innerHeight - 500) {
    (document.querySelector('.easy-operate-image') as any).style.left = '84rem'
  }

  if (easyOperateTop > window.innerHeight) {
    easyOperate.style.top = '80rem'
  } else {
    easyOperate.style.top = Math.max(80 - (window.innerHeight - easyOperateTop), 0) + 'rem'
  }

  // 部署模块动效
  const arrange: any = document.querySelector('.arrange');
  const arrangeTops: any = arrange.getBoundingClientRect().top;
  if (arrangeTops > window.innerHeight) {
    arrange.style.top = '60rem'
  } else {
    arrange.style.top = Math.max(60 - (window.innerHeight - arrangeTops), 0) + 'rem'
  }

  // 部署模块动效
  const studioVideo: any = document.querySelector('.studio-video');
  const studioVideoTops: any = studioVideo.getBoundingClientRect().top;
  if (studioVideoTops > window.innerHeight) {
    studioVideo.style.top = '60rem'
  } else {
    studioVideo.style.top = Math.max(60 - (window.innerHeight - studioVideoTops), 0) + 'rem'
  }

  // 成本模块动效
  const costChild: any = document.querySelectorAll('.cost-wrap>div>div');
  const costTop: any = (document.querySelector('.cost-wrap') as any).getBoundingClientRect().top;
  costChild.forEach((ele: any, i: number) => {
    if (costTop < (window.innerHeight - (i + 1) * 100)) {
      ele.style.left = 0
    } else if (costTop < (window.innerHeight - (i + 1) * 100 + 100)) {
      ele.style.left = '80rem'
    } else {
      ele.style.left = ele.getBoundingClientRect().width + 80 * screenRatio + 'px'
    }
  });
}

onMounted(async () => {
  window.addEventListener('scroll', scrollEvent, false)
  window.scrollTo(0, 0)

  const home_banner_mp4: any = await asynchronousRetrieval('webimg/video/home-banner.mp4');
  const studio_mp4: any = await asynchronousRetrieval('webimg/video/studio.mp4');
  const entertainment_mp4: any = await asynchronousRetrieval('webimg/video/entertainment.mp4');
  const particles_moment_mp4: any = await asynchronousRetrieval('webimg/images/home/particles-moment.mp4');
  const industry_mp4: any = await asynchronousRetrieval('webimg/video/industry.mp4');
  const education_mp4: any = await asynchronousRetrieval('webimg/video/education.mp4');
  const wechat_gif: any = await asynchronousRetrieval('webimg/gif/wechat.gif');
  const VR_gif: any = await asynchronousRetrieval('webimg/gif/VR.gif');
  const AR_gif: any = await asynchronousRetrieval('webimg/gif/AR.gif');
  const web_gif: any = await asynchronousRetrieval('webimg/gif/web.gif');
  const core_advantages_bg: any = await asynchronousRetrieval('webimg/images/home/core-advantages-bg.png');
  const ycaozuo_gif: any = await asynchronousRetrieval('webimg/ycaozuo.gif');
  const partner_bg: any = await asynchronousRetrieval('webimg/images/home/partner-bg.png');
  const partner3: any = await asynchronousRetrieval('webimg/images/home/partner3.png');
  const bg_mask: any = await asynchronousRetrieval('webimg/images/home/bg-mask.png');
  loadUrl.value = { home_banner_mp4, studio_mp4, entertainment_mp4, particles_moment_mp4, industry_mp4, education_mp4, wechat_gif, VR_gif, AR_gif, web_gif, core_advantages_bg, ycaozuo_gif, partner_bg, partner3, bg_mask }

  metaversePlatformList.value[0].url = entertainment_mp4;
  metaversePlatformList.value[1].url = industry_mp4;
  metaversePlatformList.value[2].url = education_mp4;
  projectTypeList.value[0].imgs[0].url = wechat_gif;
  projectTypeList.value[1].imgs[0].url = VR_gif;
  projectTypeList.value[2].imgs[0].url = AR_gif;
  projectTypeList.value[3].imgs[0].url = web_gif;
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollEvent, false)
  clearInterval(timer)
  store.state.zoomScreened = false
  window.scrollTo(0, 0)
})

const openTimer = () => {
  clearInterval(timer)
  timer = setInterval(() => {
    viewTime.value += 1;
    if (viewTime.value > fusionVideo.value[activeFusionIndex.value]) {
      if (store.state.zoomScreened) {
        activeFusionIndex.value += 1;
        if (activeFusionIndex.value > 2) {
          activeFusionIndex.value = 0
        }
        console.log(activeFusionIndex.value, 2222)
      }
      viewTime.value = 1;
    }
  }, 1000)
}

watch(viewTime, (newState) => {
  const metaverseLine: any = document.querySelectorAll('.metaverse-line>div');
  metaverseLine.forEach((ele: any) => {
    ele.style.width = 0
  })
  metaverseLine[activeFusionIndex.value].style.width = 300 / fusionVideo.value[activeFusionIndex.value] * newState + 'rem'
})

watch(activeFusionIndex, async(newState) => {
  const windowVideo: any = document.querySelectorAll('.fusion-window>video');
  windowVideo[newState].currentTime = 0;
})
</script>
<style scoped lang="less">
.header-video {
  width: 100%;
  height: 56.25vw;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .video-mask {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E1EFFF 100%);
    transition: all .5s;
  }

  &>video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .video-text {
    width: 43.125vw;
    position: absolute;
    top: 13.958vw;
    left: 50%;
    margin-left: -21.5625vw;
    font-size: 1.0417vw;
    color: #FFFFFF;

    &>div {
      text-align: center;
    }

    .logo-text {
      width: 43.125vw;
      height: 5.833vw;
      background-image: url(~@/assets/images/home/mixed-space.png);
      background-size: 100% 100%;
    }

    .link-room {
      font-size: 2.135vw;
      color: #FFFFFF;
      margin-top: 1.0417vw;
    }

    .text-tips {
      width: 28.3854vw;
      margin-left: 7.5vw;
      margin-top: 2.1875vw;
    }

    .experience-now {
      position: absolute;
      left: 50%;
      margin-left: -4.8958vw;
      width: 9.7916vw;
      height: 3.3854vw;
      background: #0675FF;
      border-radius: 1.71875vw;
      margin-top: 3.75vw;
      padding: 0 0.36458vw 0 1.25vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      z-index: 1;

      .experience-btn {
        width: 2.6vw;
        height: 2.6vw;
        background: url(~@/assets/images/next-icon.png);
        background-size: 100% 100%;
      }
    }
  }
}

.core-advantages {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E1EFFF;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &>div {
    position: relative;
    width: 1220rem;
    border-radius: 80rem;
    padding: 120rem 0 124rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 24rem;
    color: #434343;
    z-index: 1;

    .core-title {
      font-size: 60rem;
      color: #0675FF;
      margin-bottom: 20rem;
      font-weight: 600;
    }

    .core-scene {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 93rem;
      width: 100%;

      &>div:first-child {
        height: 686rem;
        padding: 0;
        overflow: hidden;

        video {
          width: calc(100% + 4rem);
          height: calc(100% + 4rem);
          position: relative;
          left: -2rem;
          top: -2rem;
          object-fit: fill;
        }
      }

      .studio-video {
        position: relative;
        transition: all .5s;
        border: 1px solid #999;
      }

      &>div {
        position: relative;
        width: 1220rem;
        height: 533rem;
        background: #ddd;
        border-radius: 50rem;
        padding: 60rem 50rem;
        box-sizing: border-box;
        text-align: left;
        margin-bottom: 20rem;

        .title {
          font-weight: 600;
          font-size: 48rem;
          color: #000000;
          margin-bottom: 110rem;
        }

        .cost-wrap {
          padding-right: 54rem;
          background-color: #fff;

          &>div {
            height: 238rem;
            border-right: 1px solid rgba(24, 24, 24, 0.2);
            box-sizing: border-box;
            text-align: left;
            overflow: hidden;
            display: inline-block;
            width: 50%;
            float: left;
            box-sizing: border-box;

            &>div {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              height: 166rem;
              font-size: 46rem;
              color: #000000;
              transition: all ease-in-out .5s;
              font-weight: 600;

              &>div:first-child {
                font-size: 25rem;
                color: #000000;
                font-weight: 600;
              }

              &>div:last-child {
                font-size: 19rem;
                color: #000000;
              }
            }
          }

          &>div:nth-child(2n) {
            padding-left: 50rem;
          }
        }
      }

      .cost-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        background-color: transparent;
        height: 805rem;

        .cost-base {
          position: relative;
          transition: all .5s;
        }

        &>div {
          width: 600rem;
          height: 805rem;
          border-radius: 50rem;
          padding-top: 80rem;
          padding-left: 84rem;
          box-sizing: border-box;
        }
      }

      .high-precision {
        position: relative;
        background-color: #F5F9F3;
        background: url(~@/assets/images/home/high-precision-bg.png);
        background-size: 100% 100%;
        overflow: hidden;
        transition: all .5s;

        .high-precision-image {
          width: 538rem;
          height: 357rem;
          background: url(~@/assets/images/home/gjingdu.png);
          background-size: 100% 100%;
          position: absolute;
          left: -466rem;
          transition: all 1s;
          z-index: 2;
        }

        .precision-text {
          margin-top: -45rem;
          font-size: 28rem;
          color: #000000;
          line-height: 38rem;
          font-weight: 600;
        }

        &::before {
          content: '';
          position: absolute;
          left: 19rem;
          top: 17rem;
          width: 600rem;
          height: 805rem;
          // background: url(~@/assets/images/home/point-bg.png);
          background-color: #f5f9f3;
          background-size: 100% 100%;
          z-index: -1;
        }

        &>div:last-child {
          font-size: 24rem;
          color: #000000;
          position: absolute;
          right: 52rem;
          bottom: 0;
          line-height: 34rem;
        }
      }

      .easy-operate {
        position: relative;
        background-color: #E6F9FF;
        overflow: hidden;
        height: 805rem;
        padding-left: 84rem;
        transition: all .5s;

        .easy-operate-image {
          width: 1052rem;
          height: 491rem;
          position: absolute;
          // left: 53rem;
          left: -1226rem;
          bottom: 0;
          transition: all 1s;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &>div:last-child {
          height: 379rem;
          font-size: 28rem;
          color: #000000;
          line-height: 38rem;
          text-align: left;
          position: absolute;
          left: 84rem;
          top: 171rem;
          font-weight: 600;
        }
      }

      .arrange {
        position: relative;
        background-color: #FFFEF9;
        overflow: hidden;
        height: 615rem;
        padding-left: 84rem;
        transition: all .5s;

        .arrange-icon {
          text-align: center;
          width: 622rem;
          margin-left: -99rem;
          margin-top: 24rem;
          font-size: 0;

          &>div {
            position: relative;
            top: -20rem;
            width: 121rem;
            height: 121rem;
            display: inline-block;
            margin: 45rem 17rem 0;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &>div:first-child {
            margin-left: 20rem;
          }
        }

        &>div:last-child {
          width: 493rem;
          font-weight: 600;
          font-size: 28rem;
          color: #000000;
          line-height: 94rem;
          position: absolute;
          right: 52rem;
          top: 260rem;

          &>div {
            position: relative;
          }

          &>div::before {
            content: '';
            width: 8rem;
            height: 8rem;
            background-color: #000;
            position: absolute;
            left: -18rem;
            top: 41rem;
            border-radius: 100%;
          }
        }
      }

      &>div:first-child {
        height: 575rem;
      }
    }
  }
}

.fusion {
  position: relative;

  .fusion-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920rem;
    height: 1000rem;
    z-index: 12;

    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .fusion-content {
    position: relative;
    height: 1000rem;
    background-color: #000000;
    padding-top: 64rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .fusion-title {
      font-size: 61rem;
      color: #FFFFFF;
      font-weight: 600;
    }

    .fusion-tips {
      font-weight: 500;
      font-size: 24rem;
      color: #FFFFFF;
      margin-top: 12rem;
      margin-bottom: 565rem;
    }

    .fusion-window {
      position: absolute;
      top: 226rem;
      left: 50%;
      margin-left: -485rem;
      width: 971rem;
      height: 520rem;
      background: url(~@/assets/images/home/window-bg.png);
      background-size: 100% 100%;
      padding: 15rem;
      box-sizing: border-box;
      scale: 2;
      opacity: 0;
      transition: all 1s;

      video {
        width: 941rem;
        height: 490rem;
        border-radius: 30rem;
        position: absolute;
        left: 15rem;
        top: 15rem;
        opacity: 0;
        transition: all 0.5s;
        object-fit: fill;
      }
    }

    .fusion-list-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 939rem;
      text-align: left;
      margin-top: 45rem;

      &>div {
        width: 300rem;
        height: 198rem;
        position: relative;
        cursor: pointer;
      }

      .metaverse-title {
        font-weight: 600;
        font-size: 24rem;
        color: #FFFFFF;
      }

      .metaverse-line {
        position: relative;
        border-bottom: 2px solid rgba(179, 179, 179, 0.24);
        width: 300rem;
        margin: 16rem 0;
      }

      .metaverse-content {
        font-weight: 500;
        font-size: 14rem;
        color: #B2B2B2;
        width: 240rem;
      }

      .view-more {
        width: 110rem;
        height: 36rem;
        line-height: 36rem;
        border-radius: 8rem;
        border: 1px solid #FFFFFF;
        text-align: center;
        font-weight: 500;
        font-size: 14rem;
        color: #FFFFFF;
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
    }

    .fusion-list-info>div.active {
      .metaverse-title {
        color: #91F7FF;
      }
    }

    .metaverse-line>div {
      position: absolute;
      left: 0;
      top: 0;
      width: 0rem;
      height: 0;
      border-bottom: 2px solid;
      border-image: linear-gradient(225deg, rgba(3, 255, 243, 1), rgba(0, 117, 255, 0.07)) 2 2;
    }
  }
}

.show-project {
  position: relative;
  height: 1266rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &::after {
    content: '';
    width: 100%;
    height: 548rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0675FF 100%);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .show-project-title {
    font-weight: 600;
    font-size: 60rem;
    color: #0675FF;
    margin-top: 150rem;
    margin-bottom: 20px;
  }

  .show-project-tips {
    font-weight: 500;
    font-size: 20rem;
    color: #0675FF;
    color: #000;
    line-height: 35rem;
  }
}

.partner-wrap {
  position: relative;
  height: 1054rem;
  background-color: #fff;
  padding-left: 400rem;
  padding-top: 196rem;
  box-sizing: border-box;
  text-align: left;
  overflow-x: hidden;

  &>img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .serve-customers {
    font-weight: normal;
    font-size: 61rem;
    color: #000000;
  }

  .serve-tips {
    font-weight: 500;
    font-size: 32rem;
    color: #000000;
    margin: 20rem 0 30rem;
  }

  .serve-btn {
    position: relative;
    width: 152rem;
    height: 56rem;
    line-height: 56rem;
    background: #0675FF;
    border-radius: 8rem;
    text-align: center;
    font-weight: 500;
    font-size: 24rem;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 1;
  }

  .partner1,
  .partner2,
  .partner3,
  .partner4 {
    position: absolute;
    height: 156rem;
  }

  .partner1 {
    width: 718rem;
    background: url(~@/assets/images/home/partner1.png);
    background-size: 100% 100%;
    left: 960rem;
    top: 135rem;
  }

  .partner2 {
    width: 1021rem;
    background: url(~@/assets/images/home/partner2.png);
    background-size: 100% 100%;
    left: 899rem;
    top: 333rem;
  }

  .partner3 {
    width: 1686rem;
    left: 99rem;
    top: 531rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .partner4 {
    width: 2382rem;
    background: url(~@/assets/images/home/partner4.png);
    background-size: 100% 100%;
    left: -231rem;
    top: 729rem;
  }

  .partner5 {
    position: absolute;
    left: 0;
    top: 104rem;
    width: 100%;
    height: 841rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bg-video {
  position: relative;
  // width: 1920rem;
  height: 620rem;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .contact-us {
    width: 1266rem;
    height: 396rem;
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-clip: padding-box;
    backdrop-filter: blur(6rem);
    position: absolute;
    left: 50%;
    top: 120rem;
    margin-left: -633rem;
    border-radius: 12rem;
    padding: 62rem 72rem;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 32rem;
      color: #D3D3D3;
    }

    .form-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 59rem;

      &>div {
        position: relative;
        width: 267rem;
        height: 40rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8rem;
      }
    }

    .submit-btn {
      width: 152rem;
      height: 56rem;
      line-height: 56rem;
      background: #0675FF;
      border-radius: 8rem;
      text-align: center;
      font-weight: 500;
      font-size: 24rem;
      color: #FFFFFF;
      margin-top: 80rem;
      margin-left: 470rem;
      cursor: pointer;
    }
  }

  .contact-service {
    position: relative;
    width: 1266rem;
    height: 396rem;
    position: absolute;
    left: 50%;
    top: 120rem;
    margin-left: -633rem;
    border-radius: 12rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 12rem;
    border: 1px solid #464646;
    backdrop-filter: blur(12rem);
    padding: 40rem 87rem;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 32rem;
      color: #fff;
    }

    .title-tips {
      font-weight: 400;
      font-size: 14rem;
      color: #FFFFFF;
      margin-top: 12rem;
      margin-bottom: 50rem;
    }

    .service-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14rem;
        color: #FFFFFF;

        img {
          width: 123rem;
          height: 123rem;
          margin-bottom: 6rem;
        }
      }

      &>div {
        width: 356rem;
        height: 187rem;
        background: #151515;
        border-radius: 12rem;
        border: 1px solid #4A4A4A;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 40rem 0;
        box-sizing: border-box;

        .fontBold {
          font-weight: bold;
          font-size: 20rem;
          color: #FFFFFF;
        }

        .fontNormal {
          font-weight: 500;
          font-size: 14rem;
          color: #7C7C7C;
        }

      }
    }

    .closed {
      width: 30rem;
      height: 30rem;
      background: url(~@/assets/images/home/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 30rem;
      top: 25rem;
      cursor: pointer;
    }
  }
}
</style>