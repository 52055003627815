<template>
  <div class="solution-wrap">
    <div class="solution-banner">
      <img :src="loadUrl[solutionKeyList[activeSolutionIndex]]" />
      <HeadCard :activeSolutionIndex="activeSolutionIndex"></HeadCard>
    </div>
    <div class="solution-content" :class="{ 'not-margin': [3, 4].includes(activeSolutionIndex) }">
      <SpatialVision v-if="activeSolutionIndex == 3"></SpatialVision>
      <ArWechat v-if="activeSolutionIndex == 4"></ArWechat>
      <VRSimulation v-if="activeSolutionIndex == 5"></VRSimulation>
      <VirtualMuseum v-if="activeSolutionIndex == 6"></VirtualMuseum>
      <NavigationGuide v-if="activeSolutionIndex == 7"></NavigationGuide>
      <MetaverseView v-if="activeSolutionIndex == 8"></MetaverseView>
      <entertainment-plan v-if="activeSolutionIndex == 0"></entertainment-plan>
      <industry-model v-if="activeSolutionIndex == 1"></industry-model>
      <education-model v-if="activeSolutionIndex == 2"></education-model>
    </div>
    <contact-us v-if="store.state.contactUsTop"></contact-us>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, watch } from 'vue'
import ArWechat from '@/components/ArWechat.vue';
import VRSimulation from '@/components/VRSimulation.vue';
import VirtualMuseum from '@/components/VirtualMuseum.vue';
import NavigationGuide from '@/components/NavigationGuide.vue';
import MetaverseView from '@/components/MetaverseView.vue';
import HeadCard from '@/components/common/HeadCard.vue';
import SpatialVision from '@/components/SpatialVision.vue';
import EntertainmentPlan from '@/components/EntertainmentPlan.vue';
import IndustryModel from '@/components/IndustryModel.vue';
import EducationModel from '@/components/EducationModel.vue';
import router from '@/router';
import store from '@/store';
import ContactUs from '@/components/ContactUs2.vue'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const solutionKeyList = ['jjfa_bg0', 'jjfa_bg1', 'jjfa_bg2', 'spatial_vision', 'ar_wechat', 'vr_simulation', 'virtual_museum', 'navigation_guide', 'metaverse'];
const activeSolutionIndex = ref(1);

onMounted(async () => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  activeSolutionIndex.value = +path.query.type || store.state.programmeIndex;

  const lastBanner = await asynchronousRetrieval(`webimg/images/solution/${solutionKeyList[activeSolutionIndex.value]}.png`);
  loadUrl.value[solutionKeyList[activeSolutionIndex.value]] = lastBanner;
})

watch(() => store.state.programmeIndex, async(newState: any) => {
  if (newState != activeSolutionIndex.value) {
    activeSolutionIndex.value = newState;
    const lastBanner = await asynchronousRetrieval(`webimg/images/solution/${solutionKeyList[newState]}.png`);
    loadUrl.value[solutionKeyList[activeSolutionIndex.value]] = lastBanner;
    store.state.contactUsTop = null;
  }
})
</script>

<style scoped lang="less">
.solution-wrap {
  background: #EFEFEF;
  min-height: calc(100vh - 400rem);

  .solution-banner {
    position: relative;
    display: flex;
    justify-content: center;
    height: 41.1458vw;
    text-align: center;
    padding-top: 8.697vw;
    box-sizing: border-box;
    font-size: 2.1354vw;
    color: #FFFFFF;

    &>img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &>div {
      position: relative;
      z-index: 1;
    }

    &>div:first-child {
      position: relative;
      font-weight: 600;
      font-size: 3.333vw;
      color: #FFFFFF;
      margin-bottom: 1.302vw;

      span {
        margin: 0 2.3958vw;
      }
    }
  }

  .solution-content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .solution-tab {
      font-weight: 400;
      font-size: 20rem;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60rem;

      &>div {
        margin: 0 30rem;
        padding: 0 10rem;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        height: 60rem;
        line-height: 60rem;
      }

      .active {
        font-weight: 800;
        font-size: 20rem;
        color: #2E76FF;
        border-color: #2E76FF;
      }
    }
  }

  .not-margin {
    margin-top: 0rem;
  }
}
</style>