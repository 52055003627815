<template>
  <header-view v-if="store.state.userAgent == 'pc'" :change-path="changePath" :hideTitle="hideTitle"></header-view>
  <mobile-header v-if="store.state.userAgent == 'mobile'"></mobile-header>
  <div class="router-wrap" :class="store.state.userAgent == 'pc' ? 'web-wrap' : 'mobile-wrap'">
    <router-view />
    <bottom-navigation v-if="store.state.userAgent == 'pc'"></bottom-navigation>
    <mobile-bottom-navigation v-if="store.state.userAgent == 'mobile'"></mobile-bottom-navigation>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderView from '@/components/HeaderView.vue';
import MobileHeader from '@/components/mobile/HeaderView.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import MobileBottomNavigation from '@/components/mobile/BottomNavigation.vue';

const router = useRouter()
const store = useStore()
const hideTitle = ref(false)


const changePath = (path: string) => {
  console.log(path)
}

// 路由拦截，这里主要根据路由确定一些组件的显隐性
router.beforeEach((to, from, next) => {
  hideTitle.value = Boolean((to.meta && to.meta.hideTitle));
  const pathInfo = JSON.stringify({ query: to.query || {} })
  window.sessionStorage.setItem('path', pathInfo)
  window.localStorage.setItem('activePath', to.fullPath)
  store.state.activePath = to.fullPath;
  if (store.state.userAgent == 'mobile') {
    window.scrollTo(0, 0)
  }
  next();
});

onMounted(() => {
  setRem()
  window.addEventListener('resize', () => {
    setRem()
  }, false)
  // 判断是不是移动端
})

const setRem = () => {
  let rootDom: any = document.querySelector('html');
  let rootWidth = document.documentElement.clientWidth || document.body.clientWidth;

  var userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.match(
      /(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone|windows mobile|windows ce|ucweb|rv:1.2.3.4|midp)/i
    )
  ) {
    rootDom.style.fontSize = rootWidth / 750 + 'px';
    store.state.userAgent = 'mobile'
  } else {
    rootDom.style.fontSize = Math.min(Math.max(rootWidth / 1920, 1), 3) + 'px';
    store.state.userAgent = 'pc'
  }

}
</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  color: #333333;
}

body {
  font-size: 14rem;
  font-family: PingFangSC-Regular;
}

#app {
  font-family: "PingFang SC", "Lantinghei SC", "Microsoft YaHei", "HanHei SC", "Helvetica Neue", "Open Sans", "Hiragino Sans GB", '微软雅黑', STHeiti, "WenQuanYi Micro Hei", Arial, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.router-wrap {
  position: relative;
  font-family: PingFangSC-Regular;
  box-sizing: border-box;
  min-height: calc(100vh - 60rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000000;

  &>div {
    width: 100%;
  }
}

.web-wrap {
  padding-bottom: 400rem;
}

.mobile-wrap {
  padding-bottom: 50rem;
  padding-top: 72rem;
  overflow: hidden;
}

.counselling-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
  padding-bottom: 100rem;

  div {
    width: 142rem;
    height: 46rem;
    line-height: 46rem;
    background: #0675FF;
    border-radius: 33rem;
    font-weight: 500;
    font-size: 20rem;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
  }
}

.error-text,
.error-cn,
.error-n,
.error-i {
  position: relative;
}

.error-text::after,
.error-cn::after,
.error-n::after,
.error-i::after {
  content: '输入正确的手机号';
  position: absolute;
  left: 0;
  bottom: -26rem;
  width: 180rem;
  height: 20rem;
  line-height: 20rem;
  font-size: 13rem;
  color: #d81417;
  text-align: left;
}

.error-cn::after {
  content: '请输入公司名称'
}

.error-n::after {
  content: '请输入姓名'
}

.error-i::after {
  content: '请选择所属行业'
}

.title-font-style {
  font-weight: 600;
  font-size: 60rem;
  color: #333333;
  font-family: PingFangSC-Medium;
}

.form-wrap,
.form-wrap-m {
  .el-input__wrapper {
    background-color: transparent;
    box-shadow: none !important;
    border: none !important;
    padding: 6rem 20rem;
  }

  .el-select__wrapper {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    height: 100%;
  }

  .el-select__wrapper {
    padding: 6rem 20rem;
    line-height: 40rem;
    min-height: 40rem;

    .el-icon {
      width: 20rem;
    }
  }

  .el-input__inner,
  .el-select__placeholder {
    font-weight: 500;
    font-size: 16rem;
    height: 40rem;
    color: #CDCDCD;
  }

  .is-transparent>span {
    color: #a8abb2;
    font-weight: 400;
    font-size: 16rem;
  }
}

.form-wrap-m {
  .el-select__wrapper {
    box-sizing: content-box;
  }

  .el-input__inner,
  .el-select__placeholder {
    font-size: 22rem;
  }

  .is-transparent>span {
    font-size: 22rem;
  }
}

.news-detail img {
  max-width: 100%;
  height: auto;
}

.news-detail video {
  max-width: 100%;
  height: auto;
}

.news-detail .detail-html {
  padding: 30rem;
  box-sizing: border-box;
  color: rgb(71, 69, 69);
}
</style>
