<template>
  <div class="news">
    <div class="news-banner">
      <img :src="loadUrl.news" />
      <div>{{ store.state.programmeIndex == 6 ? '行业新闻' : '公司新闻' }}</div>
    </div>
    <div class="news-content">
      <div v-for="(item, index) in newsList" :key="index" @click="toOtherPath(item.relatedHtmlId, item.title)">
        <div>
          <img :src="item.ossPath" />
        </div>
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="introduction">{{ item.introduction }}</div>
          <div class="createTime">{{ item.createTime.slice(0, 10) }}</div>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="newsListAll.length > 8">
      <div class="prev" @click="changeData(Math.max(0, paginationIndex - 1) + 1)"></div>
      <div @click="changeData(item)" v-for="(item, index) in paginationValue"
        :class="paginationIndex == index ? 'active' : ''" :key="item">{{
          item }}</div>
      <div class="next" @click="changeData(Math.min(paginationIndex + 1, paginationValue - 1) + 1)"></div>
    </div>
    <!-- <contact-us></contact-us> -->
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, reactive } from 'vue'
import { selectCommonElement, addConsult } from '@/api'
import router from '@/router';
import store from '@/store';
// import ContactUs from '@/components/ContactUs.vue'
import { asynchronousRetrieval } from '@/utils'

const loadUrl: any = ref({})
const newsListAll: any =  ref([])
const newsList: any = ref([])
const paginationIndex = ref(0)
const paginationValue = ref(1)

const toOtherPath = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
  store.state.pageTitleStr = `新闻中心 / 新闻详情 / ${title}`
}

const changeData = (value: number) => {
  paginationIndex.value = value - 1;
  newsList.value = [...newsListAll.value].slice((value - 1) * 8, value * 8)
}


const getData = (columnId: number) => {
  selectCommonElement({ columnId, appId: 2 }).then((res: any) => {
    newsListAll.value = [...res].map((e: any) => {
      const date = new Date(e.createTime)
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const h = date.getHours();
      const mx = date.getMinutes();
      const s = date.getSeconds();
      e.createTime = `${y}-${m}-${d} ${h}:${mx}:${s}`
      return e;
    })
    newsList.value = [...newsListAll.value].slice(0, 8)
    paginationValue.value = Math.ceil(newsListAll.value?.length / 8) || 0
  })
}

onMounted(async () => {
  const news: any = await asynchronousRetrieval('webimg/images/about_us/news.png');
  loadUrl.value = { news }

  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  const columnId = +path.query.type || 5
  getData(columnId);
})
watch(() => store.state.programmeIndex, (newState: any) => {
  getData(newState)
})
</script>
<style scoped lang="less">
.news {
  min-height: calc(100vh - 400rem);
  background: #EFEFEF;

  .news-banner {
    position: relative;
    height: 21.875vw;
    line-height: 26.302vw;
    text-align: center;
    font-weight: 600;
    font-size: 3.33vw;
    color: #FFFFFF;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &>div {
      position: relative;
      z-index: 1;
    }
  }

  .news-content {
    padding-top: 24rem;
    padding-bottom: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    &>div {
      width: 1120rem;
      height: 353rem;
      background: #FFFFFF;
      border-radius: 12rem;
      margin-bottom: 24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24rem;
      box-sizing: border-box;
      cursor: pointer;

      &>div:first-child {
        width: 542rem;
        height: 305rem;
        background: #D8D8D8;
        border-radius: 12rem;
        // border: 1px solid #979797;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &>div:last-child {
        width: 503rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        .title {
          font-weight: bold;
          font-size: 24rem;
          color: #414141;
          margin-bottom: 16rem;
          line-height: 40rem;
        }

        .introduction {
          font-weight: 400;
          font-size: 18rem;
          color: #414141;
          margin-bottom: 26rem;
          line-height: 30rem;
        }
      }
    }
  }

  .pagination {
    margin: -50rem 0 67rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      width: 24rem;
      height: 24rem;
      line-height: 24rem;
      border-radius: 2rem;
      font-weight: 400;
      font-size: 14rem;
      color: #999999;
      margin: 0 5rem;
      text-align: center;
      cursor: pointer;
    }

    &>div.active {
      background: #F7F7F7;
      color: #414141;
    }

    .prev {
      width: 16rem;
      height: 16rem;
      background-image: url(~@/assets/images/solution/left-c.png);
      background-size: 100% 100%;
    }

    .next {
      width: 16rem;
      height: 16rem;
      background-image: url(~@/assets/images/solution/left-c.png);
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
}
</style>