<template>
  <div class="spatia-vision">
    <div class="boot-first">
      <header>为你构建室内外稳定的<span>空间计算能力</span></header>
      <main>
        <div class="item" v-for="item in modelTypes[MODEL]" :key="item.header">
          <img :src="item.image" alt="">
          <div class="content">
            <h3>{{ item.header }}</h3>
            <ul>
              <li v-for="remark in item.remarkList" :key="remark">{{ remark }}</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
    <div class="wmdys">
      <sub-title mode-class="black-style" title-text="我们的优势"></sub-title>
      <div class="wmdys-content">
        <div class="left">
          <header>
            <img src="@/assets/images/ar-images/icon/cbys-icon.png" class="l-iamge" alt="">
            <div class="r-head">成本优势</div>
          </header>
          <div class="line">· 技术使用成本低；</div>
          <div class="line">· 云SAAS化服务降低使用门槛。</div>
        </div>
        <div class="right">
          <header>
            <img src="@/assets/images/ar-images/icon/cjys-icon.png" class="l-iamge" alt="">
            <div class="r-head">场景优势</div>
          </header>
          <div class="line">· 现实空间三维建模、复杂环境感知、视觉导航上优势显著；</div>
          <div class="line">· 在机器人、自动驾驶、医疗、消费电子等领域具备可拓展前景。</div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.kjsj"></example-introduction>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { modelTypes } from '@/config';
import SubTitle from '@/components/SubTitle.vue';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue';
import { exampleDatas } from '@/config/index'

const MODEL = 'vision_ar'

</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

.spatia-vision {
  background-color: #FFFFFF;



  .boot-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    height: 743rem;
    background: #E1EFFF;

    header {
      position: relative;
      color: #414141;
      font-size: 32rem;
      text-align: left;
      margin-bottom: 64rem;

      &::after {
        content: '';
        width: 100%;
        height: 13rem;
        background: rgba(35, 117, 255, 0.2);
        position: absolute;
        left: 0;
        bottom: -1rem;
      }

      span {
        color: #2E76FF;
      }
    }

    main {
      display: flex;

      .item {
        width: 268rem;
        height: 393rem;
        background: #FFFFFF;
        border-radius: 12rem;
        margin-right: 16rem;

        img {
          width: 268rem;
          height: 157rem;
        }

        .content {
          box-sizing: border-box;
          padding: 20rem 24rem 0;

          h3 {
            font-weight: 500;
            font-size: 20rem;
            color: #333333;
            margin-bottom: 20rem;
            text-align: left;
            box-sizing: border-box;
            padding-left: 1rem;
          }


          ul {
            padding-left: 20rem;

            li {
              font-size: 14rem;
              color: #5C5C5C;
              text-align: left;
              line-height: 24rem;
              text-align: justify;
            }
          }
        }
      }
    }
  }

  .wmdys {
    width: 1920rem;
    height: 523rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;

    .wmdys-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        margin-right: 16rem;
      }

      .left,
      .right {
        width: 552rem;
        height: 210rem;
        border-radius: 12rem;
        overflow: hidden;
        background: url('@/assets/images/ar-images/youshiback.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 26rem;

        header {
          display: flex;
          align-items: center;
          margin: 42rem 0 16rem 0;
          box-sizing: border-box;
          padding-left: 42rem;


          .l-iamge {
            width: 56rem;
            height: 54rem;
            margin-right: 24rem;
          }

          .r-head {
            font-weight: 500;
            font-size: 20rem;
            color: #414141;
          }
        }

        .line {
          font-size: 14rem;
          color: #5C5C5C;
          line-height: 24rem;
          text-align: left;
          box-sizing: border-box;
          padding-left: 42rem;
        }
      }
    }
  }
}
</style>