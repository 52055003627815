<template>
  <div class="step-process">
    <div class="subtitle">
      <span v-for="(item, index) in stepTitle" :key="index">{{ item }}</span>
    </div>
    <div class="step-box">
      <!-- <div :style="{ width: `calc(${100 / stepList.length}% - ${(stepList.length - 1) * 35 / stepList.length}rem)` }"
        v-for="(item, index) in stepList" :key="index">{{
          item }}</div> -->
      <img :src="stepData[stepType]" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  stepTitle: {
    default: null,
    type: Object
  },
  stepType: {
    default: 'step1',
    type: String
  },
})

const stepData: any = {
  step1: require('@/assets/images/ar-images/xnfz-bottom.png'),
  step2: require('@/assets/images/ar-images/xnfz-bottom2.png')
}
</script>
<style scoped lang="less">
.step-process {
  background-color: #EFEFEF;
  padding-top: 120rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 32rem;
    color: #414141;
    line-height: 40rem;
    display: inline-block;
    padding: 0 16rem;
    min-width: 204rem;
    box-sizing: border-box;
    margin-bottom: 59rem;

    &>span {
      position: relative;
      z-index: 1;
    }

    &>span:last-child {
      color: #2E76FF;
      margin-left: 10rem;
    }

    &>span:first-child {
      color: #414141;
      margin-left: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(46, 118, 255, 0.30);
      position: absolute;
      left: 0;
      bottom: -1rem;
    }
  }

  .step-box {
    width: 1120rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60rem;

    img {
      height: 101rem;
      width: auto;
    }

    &>div {
      height: 101rem;
      line-height: 101rem;
      background: #FFFFFF;
      border-radius: 10rem;
    }
  }
}
</style>