<template>
  <div class="entertainment">
    <div class="title-font-style">文娱解决方案</div>
    <div class="illustrate">
      <div>将元宇宙与内容资源融合创新，应用虚拟现实和新媒体技术打造沉浸式的时空新体验，实现“文化+科技”“文娱+消费”的新业态模式，推动文娱产业升级。</div>
    </div>
    <div class="subtitle">发展前景</div>
    <div class="fzqj-box">
      <div class="fzqj">
        <div v-for="(item, index) in prospects" :key="index">
          <img :src="item.url" />
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <div>
          <img class="l1" :src="loadUrl.positioning" />
          <div class="l2">空间定位</div>
          <img class="l3" :src="loadUrl.triangle" />
          <div class="l4">准</div>
          <div class="l5">
            <div>自研空间定位算法</div>
            <div>厘米级视觉定位</div>
            <div>空间导航精准</div>
            <div>支持大空间定位</div>
          </div>
        </div>
        <div>
          <img class="l1" :src="loadUrl.arrange" />
          <div class="l2">交付部署</div>
          <img class="l3" :src="loadUrl.triangle" />
          <div class="l4">快</div>
          <div class="l5">
            <div>SaaS化服务</div>
            <div>快速调整内容布局</div>
            <div>2天内完成空间构建</div>
            <div>现场体验式交付</div>
          </div>
        </div>
        <div>
          <img class="l1" :src="loadUrl.edit" />
          <div class="l2">场景编辑</div>
          <img class="l3" :src="loadUrl.triangle" />
          <div class="l4">简</div>
          <div class="l5">
            <div>3D场景地图</div>
            <div>可视化交互</div>
            <div>“拖拉拽”操作</div>
            <div>所“建”即所得</div>
          </div>
        </div>
        <div>
          <img class="l1" :src="loadUrl.application" />
          <div class="l2">支持应用</div>
          <img class="l3" :src="loadUrl.triangle" />
          <div class="l4">多</div>
          <div class="l5">
            <div>AR/MR眼镜</div>
            <div>移动端APP</div>
            <div>小程序AR</div>
            <div>Web</div>
          </div>
        </div>
        <div>
          <img class="l1" :src="loadUrl.positioning" />
          <div class="l2">内容扩展性</div>
          <img class="l3" :src="loadUrl.triangle" />
          <div class="l4">强</div>
          <div class="l5">
            <div>数字多媒体</div>
            <div>动画特效</div>
            <div>手势互动</div>
            <div>3D数字人</div>
          </div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <video :src="loadUrl.entertainmentMp4" autoplay loop muted playsinline webkit-playsinline></video>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toDetail(item.relatedHtmlId, item.title)" v-for="(item, index) in casesListAll" :key="index">
          <img :src="item.ossPath" />
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="detail">
              {{ item.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesListAll: any = ref([])
const prospects: any = ref(
  [
    {
      title: '消费升级',
      content: '当下，国内新型消费潜力迸发，以沉浸式旅游、文化旅游等为特点的文娱旅游正成为各地积极培育的消费增长点。同时，数实融合不断深化，实体经济数字化、智能化、绿色化转型不断加快，为经济社会发展持续赋能。',
    },
    {
      title: '政策引领',
      content: '工信部等五部门联合印发《元宇宙产业创新发展三年行动计划（2023-2025年）》，北上广深、南京等数十多个城市纷纷出台元宇宙发展相关政策，希望借势元宇宙的机会加速传统产业数字化转型并大力发展数字经济，结合地方特色打造丰富的元宇宙标杆场景和应用。',
    },
    {
      title: '数智未来',
      content: '人类社会数字化、智能化是不可逆的发展趋势，AIGC的应用将会极大的提高人类的生产效率，也将会加快数实融合、数实互促、元宇宙的模态到来。',
    }
  ]
)

const toDetail = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
}

onMounted(async () => {
  selectCommonElement({ columnId: 4, appId: 2, createTimeOrder: 1 }).then((res: any) => {
    casesListAll.value = [...res].filter(e => e.industry == 1)
  })

  const positioning: any = await asynchronousRetrieval('nfznxr-images/jjfa/positioning.png');
  const triangle: any = await asynchronousRetrieval('nfznxr-images/jjfa/triangle.png');
  const arrange: any = await asynchronousRetrieval('nfznxr-images/jjfa/arrange.png');
  const edit: any = await asynchronousRetrieval('nfznxr-images/jjfa/edit.png');
  const application: any = await asynchronousRetrieval('nfznxr-images/jjfa/application.png');
  const fzqj1: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj1.png');
  const xiaofei: any = await asynchronousRetrieval('nfznxr-images/jjfa/xiaofei.png');
  const fzqj2: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj2.png');
  const zhengce: any = await asynchronousRetrieval('nfznxr-images/jjfa/zhengce.png');
  const fzqj3: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj3.png');
  const shuzhi: any = await asynchronousRetrieval('nfznxr-images/jjfa/shuzhi.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  const entertainmentMp4: any = await asynchronousRetrieval('webimg/video/entertainment.mp4');
  loadUrl.value = { positioning, triangle, arrange, edit, application, fzqj1, xiaofei, fzqj2, zhengce, fzqj3, shuzhi, fays_bg, entertainmentMp4 }

  prospects.value[0].url = fzqj1
  prospects.value[0].src = xiaofei
  prospects.value[1].url = fzqj2
  prospects.value[1].src = zhengce
  prospects.value[2].url = fzqj3
  prospects.value[2].src = shuzhi
})


</script>
<style scoped lang="less">
.entertainment {
  padding-top: 60rem;
  overflow: hidden;
  width: 100vw;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 24rem;
      color: #4A4A4A;
      width: 600rem;
      line-height: 36rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 32rem;
    color: #000000;
    line-height: 44rem;
    display: inline-block;
    padding: 0 16rem;

    &::after {
      content: '';
      width: 100%;
      height: 20rem;
      background: rgba(35, 117, 255, 0.2);
      position: absolute;
      left: 0;
      bottom: -5rem;
    }
  }

  .fzqj-box {
    position: relative;
    width: 100vw;
    height: 660rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 48rem 0 80rem;
    overflow: hidden;
    overflow-x: auto;

    .fzqj {
      position: absolute;
      left: 0;
      top: 0;
      width: 1392rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 120rem;

      &>div {
        width: 448rem;
        height: 660rem;
        font-size: 0;
        box-shadow: 0rem 2rem 12rem 0rem rgba(0, 0, 0, 0.07);
        border-radius: 8rem;
        overflow: hidden;

        &>img {
          width: 100%;
          height: 302rem;
        }

        &>div {
          padding: 32rem 29rem 0;
          font-weight: 400;
          font-size: 20rem;
          color: #5D5D5D;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 358rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 28rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100vw;
    // height: 530rem;
    margin-top: 48rem;
    overflow: hidden;
    overflow-x: auto;
    padding: 36rem 0;

    &>img {
      width: 1496rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      width: 1256rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 120rem;
    }

    &>div>div {
      position: relative;
      width: 232rem;
      height: 480rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8rem;
      padding: 20rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 1;

      .l1 {
        width: 82rem;
        height: 96rem;
      }

      .l2 {
        font-weight: 600;
        font-size: 24rem;
        color: #333333;
        line-height: 25rem;
        font-family: PingFangSC-Medium;
      }

      .l3 {
        width: 64rem;
        height: 44rem;
      }

      .l4 {
        font-weight: 600;
        font-size: 32rem;
        color: #4B7CFD;
        line-height: 30rem;
        font-family: PingFangSC-Medium;
        margin-top: 12rem;
      }

      .l5 {
        padding: 14rem 0;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 20rem;
        color: #414141;
        line-height: 30rem;
        width: 184rem;
        height: 204rem;
        background: rgba(75, 124, 253, 0.1);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin-top: 20rem;
        text-align: center;

        &>div {
          margin-bottom: 16rem;
        }
      }
    }
  }

  .yycj {
    padding-top: 64rem;

    .yycj-content {
      width: 100%;
      height: 382rem;
      margin-top: 48rem;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        width: 680rem;
        height: 100%;
        object-fit: fill;
        border-radius: 24rem;
        border: 1px solid #979797;
      }
    }
  }

  .yyal {
    padding-top: 64rem;
    overflow: hidden;

    .yyal-content {
      margin: 46rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 680rem;
        background: #FFFFFF;
        border-radius: 32rem;
        box-sizing: border-box;
        cursor: pointer;
        padding: 24rem 24rem 34rem;
        margin-bottom: 48rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 630rem;
          height: 354rem;
          border-radius: 20rem;
        }

        .content {
          width: 632rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 30rem;
            color: #414141;
            line-height: 48rem;
            margin-top: 10rem;
          }

          .detail {
            font-weight: 400;
            font-size: 20rem;
            color: #414141;
            line-height: 30rem;
            margin-top: 6rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>