import { createStore } from 'vuex'

export default createStore({
  state: {
    programmeIndex: 0,
    titleIndex: 0,
    pageTitle: [],
    userAgent: 'pc',
    zoomScreened: false,
    pageTitleStr: '',
    activePath: '',
    contactUsTop: <any>null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
