<template>
  <div class="metaverse">
    <div class="yycj">
      <sub-title title-text="应用场景"></sub-title>
      <div class="yycj-content">
        <main>
          <div class="item" v-for="item in modelTypes['metaverse']" :key="item.header">
            <img :src="item.image" alt="">
            <div class="content">{{ item.remarkList[0] }}</div>
            <div class="content-title">{{ item.header }}</div>
          </div>
        </main>
      </div>
    </div>
    <div class="wmdys">
      <sub-title mode-class="black-style" title-text="我们的优势"></sub-title>
      <div class="wmdys-content">
        <div class="wmdys-content-top">
          <div v-for="(item, index) in gntxData" :key="index">
            <div class="title">
              <img :src="item.url" class="l-iamge" alt="">
              <div>{{ item.title }}</div>
            </div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
        <div class="wmdys-content-bottom">
          <div class="left">
            <header>
              <img src="@/assets/images/ar-images/icon/xnszr-icon.png" class="l-iamge" alt="">
              <div class="r-head">AI虚拟数字人</div>
            </header>
            <div class="line">通过虚拟数字人导游为观众提供一对一游览引导、产品讲解和互动答疑服务。数字人形象、声音、交互动作均可定制。</div>
          </div>
          <div class="right">
            <header>
              <img src="@/assets/images/ar-images/icon/dzfa-icon.png" class="l-iamge" alt="">
              <div class="r-head">VR/AR等多种技术方案</div>
            </header>
            <div class="line">既可以采用VR全景展厅的方式，也可以采用AR增强现实的方式部署虚实互动的展厅。多种方式均可定制。</div>
          </div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.yyzzt"></example-introduction>
    <step-process :step-title="['一站式开发流程']" step-type="step2"></step-process>
    <div class="counselling-btn">
      <div class="btn" @click="handleEvent">合作咨询</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SubTitle from '@/components/SubTitle.vue';
import { modelTypes } from '@/config';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue';
import StepProcess from '@/components/common/StepProcess.vue';
import { exampleDatas } from '@/config/index';
import store from '@/store';

const gntxData = [
  {
    title: '打破时空限制',
    content: '观众可以随时随地通过虚拟身份进入展厅，不受时间和空间的限制。',
    url: require('@/assets/images/ar-images/icon/skxz-icon.png')
  },
  {
    title: '降低展览成本',
    content: '相比传统展览，元宇宙展厅无需额外租赁实体场地、布置展品等，可以大大降低展览成本。',
    url: require('@/assets/images/ar-images/icon/jdcb-icon.png')
  },
  {
    title: '提升观众体验',
    content: '通过虚拟现实和增强现实技术，元宇宙展厅可以为观众提供更加真实、沉浸式的观展体验和互动体验。',
    url: require('@/assets/images/ar-images/icon/tsty-icon.png')
  }
]

const handleEvent = () => {
  let contactUsTop: any = store.state.contactUsTop;
  contactUsTop = {
    bottom: 457 + 'rem'
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>
<style scoped lang="less">
.metaverse {
  .yycj {
    width: 1920rem;
    height: 1062rem;
    background: #E1EFFF;
    padding-top: 117rem;
    box-sizing: border-box;

    .yycj-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 100vw;
      height: 762rem;
      background: #E1EFFF;
      margin: 64rem 0 40rem;

      main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1119rem;

        .item {
          position: relative;
          width: 359rem;
          height: 334rem;
          background: #FFFFFF;
          border-radius: 20rem;
          margin-bottom: 46rem;
          overflow: hidden;
          font-size: 0;

          img {
            width: 359rem;
            height: 231rem;
          }

          .content {
            box-sizing: border-box;
            padding: 14rem 22rem 0;
            text-align: justify;
            font-weight: 400;
            font-size: 14rem;
            color: #5C5C5C;
            line-height: 24rem;

            h3 {
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
              margin-bottom: 20rem;
              text-align: left;
              box-sizing: border-box;
              padding-left: 1rem;
            }


            ul {
              padding-left: 20rem;

              li {
                font-size: 14rem;
                color: #5C5C5C;
                text-align: left;
                line-height: 24rem;
              }
            }
          }

          .content-title {
            position: absolute;
            top: 187rem;
            width: 100%;
            height: 44rem;
            line-height: 44rem;
            font-weight: 500;
            font-size: 20rem;
            color: #FFFFFF;
            text-align: center;
            background-color: rgba(46, 118, 255, 0.70);
          }
        }
      }
    }
  }

  .wmdys {
    width: 1920rem;
    height: 782rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;

    .wmdys-content {
      .wmdys-content-top {
        display: flex;
        justify-content: center;
        align-items: center;

        &>div {
          width: 366rem;
          height: 214rem;
          border-radius: 12rem;
          overflow: hidden;
          background: url('@/assets/images/ar-images/gntx-bg.png') no-repeat;
          background-size: 100% 100%;
          margin: 64rem 6rem 0;

          .title {
            display: flex;
            align-items: center;
            margin: 42rem 0 14rem 0;
            box-sizing: border-box;
            padding-left: 40rem;
            font-weight: 500;
            font-size: 20rem;
            color: #414141;

            img {
              width: 60rem;
              height: 60rem;
              margin-right: 24rem;
            }
          }

          .content {
            padding: 0 42rem;
            text-align: justify;
            font-weight: 400;
            font-size: 14rem;
            color: #3E3E3E;
            line-height: 24rem;
          }
        }
      }

      .wmdys-content-bottom {
        display: flex;
        justify-content: center;
        align-items: center;

        .left {
          margin-right: 16rem;
        }

        .left,
        .right {
          width: 552rem;
          height: 210rem;
          border-radius: 12rem;
          overflow: hidden;
          background: url('@/assets/images/ar-images/youshiback.png') no-repeat;
          background-size: 100% 100%;
          margin-top: 12rem;

          header {
            display: flex;
            align-items: center;
            margin: 42rem 0 16rem 0;
            box-sizing: border-box;
            padding-left: 42rem;


            .l-iamge {
              width: 60rem;
              height: 60rem;
              margin-right: 24rem;
            }

            .r-head {
              font-weight: 500;
              font-size: 20rem;
              color: #414141;
            }
          }

          .line {
            font-size: 14rem;
            color: #5C5C5C;
            line-height: 24rem;
            text-align: justify;
            box-sizing: border-box;
            padding: 0 42rem;
          }
        }
      }
    }
  }
}
</style>