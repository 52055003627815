<template>
  <div class="header">
    <div class="header-box">
      <div class="logo"></div>
      <div class="title-name">
        <div v-for="(item, index) in titleList"
          :class="(item.url != '/' && activePath.includes(item.url)) || (item.url == activePath) ? 'active' : ''"
          @click="toPath(index)" :key="index" @mouseenter="mouseenter(item.url)">{{
            item.name }}
        </div>
      </div>
      <a class="enter-studio" href="https://mixedspace.njyjxr.com">
        <div>进入混空studio</div>
        <div class="to-next"></div>
      </a>
      <div class="solution-title" v-if="hoverUrl.includes(solutionPath)" @mouseleave.stop="mouseleave">
        <div class="general-style">
          <div class="general-style-left">
            <div class="title">通用解决方案</div>
            <div class="general-style-left-icon"></div>
          </div>
          <div class="general-style-right">
            <div v-for="(item, index) in titleKeyList" :class="titleActiveIndex == (index + 3) && activePath.includes(hoverUrl) ? 'active' : ''"
              :key="index" @click="handleRouterIndex(index, solutionPath, 3)">{{ item }}</div>
          </div>
        </div>
        <div class="industry-style">
          <div class="general-style-left">
            <div class="title">行业解决方案</div>
            <div class="general-style-left-icon"></div>
          </div>
          <div class="general-style-right">
            <div v-for="(item, index) in industryTitleList" :class="titleActiveIndex == index && activePath.includes(hoverUrl) ? 'active' : ''"
              :key="index" @click="handleRouterIndex(index, solutionPath)">{{ item }}</div>
          </div>
        </div>
      </div>
      <div v-if="hoverUrl.includes(newsPath)" class="news-title" @mouseleave.stop="mouseleave">
        <div v-for="(item, index) in newsList" @click="handleRouterIndex(index + 5, newsPath)" :key="index">{{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from '@/router';
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const activePath = ref('')
let lastScrollTop = 0;
const titleKeyList = ref(['空间视觉技术', 'AR小程序', 'VR虚拟仿真', '虚拟博物馆', 'AR导航导览', '元宇宙展厅'])
const industryTitleList = ref(['文娱解决方案', '工业解决方案', '教育解决方案'])
const titleActiveIndex = ref(-1)
const solutionPath = '/solution'
const newsPath = '/company-news'
const hoverUrl = ref('')
let timer: any = null;
const newsList = ['公司新闻', '行业新闻']

const titleList: any = [
  {
    name: '首页',
    url: '/'
  },
  {
    name: '混空studio',
    url: '/studio'
  },
  {
    name: '解决方案',
    url: solutionPath
  },
  {
    name: '关于我们',
    url: '/about-us'
  },
  {
    name: '新闻资讯',
    url: newsPath
  }
]

const mouseenter = (path) => {
  hoverUrl.value = path;
  clearTimeout(timer)
}

const mouseleave = (path) => {
  timer = setTimeout(() => {
    hoverUrl.value = '';
  }, 200)
}

const handleRouterIndex = (index, url, offset = 0) => {
  titleActiveIndex.value = (index + offset)
  store.state.programmeIndex = titleActiveIndex.value
  activePath.value = `${url}?type=${titleActiveIndex.value}`;
  router.push(activePath.value);
  window.localStorage.setItem('activePath', activePath.value);
}

const toPath = (index: number) => {
  if (hoverUrl.value == solutionPath || hoverUrl.value == newsPath) return;
  window.scrollTo(0, 0);
  activePath.value = titleList[index].url;
  router.push(activePath.value);
  window.localStorage.setItem('activePath', activePath.value);
  store.state.programmeIndex = -1;
  titleActiveIndex.value = -1;
}

const scrollEvent = (e: any) => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  (document.querySelector('.header') as any).style.transition = 'all 1s'
  if (scrollTop > lastScrollTop) {
    (document.querySelector('.header') as any).style.opacity = 0;
  } else {
    (document.querySelector('.header') as any).style.opacity = 1;
  }
  lastScrollTop = scrollTop;
}

onMounted(() => {
  window.addEventListener('scroll', scrollEvent, false)
  activePath.value = window.localStorage.getItem('activePath') || ''
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  if (path.query.type == undefined) {
    titleActiveIndex.value = activePath.value.includes(solutionPath) ? 3 : 0;
  } else {
    titleActiveIndex.value = +path.query.type
  }
  store.state.programmeIndex = titleActiveIndex.value;
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollEvent, false)
})

watch(() => store.state.activePath, (newState) => {
  if (newState != activePath.value) {
    activePath.value = newState;
  }
})
</script>
<style scoped lang="less">
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 2.45vw;
  z-index: 10;
}

.header-box {
  position: relative;
  width: 58.33vw;
  height: 3.125vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(3, 41, 88, 0.35), inset 0px -1px 2px 0px rgba(6, 117, 255, 0.13);
  border-radius: 1.5625vw;
  padding: 0 0.83vw 0 1.67vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .news-title {
    position: absolute;
    left: 0;
    top: 3.75vw;
    width: 58.33vw;
    height: 12.08vw;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(3, 41, 88, 0.35), inset 0px -1px 2px 0px rgba(6, 117, 255, 0.13);
    border-radius: 1.5625vw;
    padding: 1.67vw 1.67vw 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &>div {
      width: 25.885vw;
      height: 8.75vw;
      font-weight: bold;
      font-size: 0.9375vw;
      color: #FFFFFF;
      line-height: 1.302vw;
      background-image: url(~@/assets/images/ar-images/gsxw.png);
      background-size: 100% 100%;
      padding: 3.698vw 0 0 14.53125vw;
      box-sizing: border-box;
      cursor: pointer;
      border: 0.2083vw solid #fff;
      border-radius: 1.1458vw;

      &:hover {
        border-color: #0675FF;
      }
    }

    &>div:last-child {
      background-image: url(~@/assets/images/ar-images/hyxw.png);
      background-size: 100% 100%;
    }
  }

  .solution-title {
    position: absolute;
    left: 0;
    top: 3.75vw;
    width: 58.33vw;
    height: 12.083vw;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(3, 41, 88, 0.35), inset 0px -1px 2px 0px rgba(6, 117, 255, 0.13);
    border-radius: 1.5625vw;
    padding: 1.67vw 2.1875vw;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.729vw;

    .general-style {
      width: 28.958vw;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .general-style-left {
        width: 7.76vw;
        height: 8.4375vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .general-style-left-icon {
          width: 5.625vw;
          height: 5.625vw;
          background: #D8D8D8;
          border: 1px solid #979797;
          background-image: url(~@/assets/images/ar-images/tyjjfa.png);
          background-size: 100% 100%;
        }
      }

      .general-style-right {
        width: 18.802vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 4.0625vw 0 2.34375vw;
        box-sizing: border-box;

        &>div {
          width: 5.52vw;
          height: 2.083vw;
          line-height: 2.083vw;
          color: #414141;
          margin-bottom: 1.25vw;
          cursor: pointer;
          border-radius: 1.04167vw;

          &.active {
            background: linear-gradient(225deg, #0375FF 0%, #3C96FF 100%)!important;
            color: #FFFFFF;
          }

          &:hover {
            background: linear-gradient(225deg, rgba(3, 117, 255, 0.15) 0%, rgba(60, 150, 255, 0.15) 100%);
          }
        }
      }
    }

    .industry-style {
      width: 20.417vw;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .general-style-left {
        width: 7.76vw;
        height: 8.4375vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .general-style-left-icon {
          width: 5.625vw;
          height: 5.625vw;
          background: #D8D8D8;
          border: 1px solid #979797;
          background-image: url(~@/assets/images/ar-images/hyjjfa.png);
          background-size: 100% 100%;
        }
      }

      .general-style-right {
        width: 18.802vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        // padding: 0 78rem 0 45rem;
        box-sizing: border-box;

        &>div {
          width: 5.52vw;
          height: 2.083vw;
          line-height: 2.083vw;
          color: #414141;
          margin-bottom: 1.25vw;
          cursor: pointer;
          border-radius: 1.0417vw;

          &.active {
            background: linear-gradient(225deg, #0375FF 0%, #3C96FF 100%)!important;
            color: #FFFFFF;
          }

          &:hover {
            background: linear-gradient(225deg, rgba(3, 117, 255, 0.15) 0%, rgba(60, 150, 255, 0.15) 100%);
          }
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 0.9375vw;
      color: #414141;
      line-height: 1.302vw;
      margin-top: 0.3125vw;
    }
  }

  .logo {
    width: 11.51vw;
    height: 1.354vw;
    background: url(~@/assets/images/logo.png);
    background-size: 100% 100%;
  }

  .title-name {
    font-weight: 500;
    font-size: 0.677vw;
    color: #151515;
    width: 22.292vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: -2.865vw;

    &>div {
      cursor: pointer;
      width: auto;
      height: 2.083vw;
      line-height: 2.083vw;
      border-radius: 1.042vw;
      padding: 0 0.78125vw;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 0.677vw;
      min-width: 3.90625vw;
      white-space: nowrap;
    }

    &>div:hover {
      background: linear-gradient(225deg, rgba(3, 117, 255, 0.15) 0%, rgba(60, 150, 255, 0.15) 100%);
    }

    &>div.active {
      background: linear-gradient(225deg, #0375FF 0%, #3C96FF 100%);
      color: #FFFFFF;
    }
  }

  .enter-studio {
    width: 9.167vw;
    height: 2.083vw;
    background: #0675FF;
    border-radius: 1.042vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4167vw 0 0.9375vw;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 0.833vw;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;

    .to-next {
      width: 1.5625vw;
      height: 1.5625vw;
      background: url(~@/assets/images/next-icon.png);
      background-size: 100% 100%;
    }
  }
}
</style>