<template>
  <div class="contact-us-wrap" :style="topObj">
    <div class="contact-us">
      <div class="closed" @click="store.state.contactUsTop = null"></div>
      <div class="title">联系我们的团队</div>
      <div>您可以通过以下几种方式联系我们，我们将为您提供专业的解答</div>
      <div class="content">
        <div>
          <img src="@/assets/images/home/customer2.png" />
          <div>扫码添加客服微信</div>
        </div>
        <div>
          <div>咨询电话</div>
          <div>400-900-6269</div>
          <div class="working-hours">工作日：09:00-18:00（北京时间）</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import store from '@/store';
import { ref, watch, onMounted } from 'vue';

const topObj: any = ref(null);

onMounted(() => {
  let contactUsTop: any = store.state.contactUsTop;
  if (contactUsTop) {
    topObj.value = { ...contactUsTop };
  }
})

watch(store.state.contactUsTop, (newState) => {
  console.log(newState)
  if (newState) {
    topObj.value = { ...newState };
    console.log(topObj.value)
  }
})
</script>
<style scoped lang="less">
.contact-us-wrap {
  width: 100vw;
  height: 459rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .contact-us {
    position: relative;
    width: 1294rem;
    height: 459rem;
    background: #000000;
    border-radius: 12rem;
    border: 1px solid #464646;
    backdrop-filter: blur(10rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 46rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14rem;
    color: #FFFFFF;

    .closed {
      width: 30rem;
      height: 30rem;
      position: absolute;
      right: 32rem;
      top: 32rem;
      background: url(~@/assets/images/home/close.png);
      background-size: 100% 100%;
      cursor: pointer;
    }

    .title {
      font-weight: bold;
      font-size: 32rem;
      line-height: 47rem;
      margin-bottom: 12rem;
    }

    .content {
      width: 743rem;
      height: 207rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 52rem;

      &>div {
        width: 366rem;
        height: 207rem;
        background: #151515;
        border-radius: 12rem;
        border: 1px solid #4A4A4A;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 41rem 0;
        font-weight: bold;
        font-size: 20rem;
        color: #FFFFFF;

        .working-hours {
          font-weight: 500;
          font-size: 14rem;
          color: #7C7C7C;
        }
      }

      &>div:first-child {
        font-weight: 400;
        font-size: 14rem;
        color: #FFFFFF;
        padding-top: 29rem;

        img {
          width: 126rem;
          height: 126rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
}
</style>