<template>
  <div class="virtual-museum">
    <div class="yycj">
      <sub-title title-text="应用场景"></sub-title>
      <div class="yycj-content">
        <div v-for="(item, index) in yycjData" :key="index">
          <img :src="item.url" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="xcxgn">
      <sub-title mode-class="black-style" title-text="功能特性"></sub-title>
      <div class="xcxgn-content">
        <div v-for="(item, index) in gntxData" :key="index">
          <div class="title">
            <img :src="item.url" class="l-iamge" alt="">
            <div>{{ item.title }}</div>
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.xnbwg"></example-introduction>
    <step-process :step-title="['一站式开发流程']" step-type="step2"></step-process>
    <div class="counselling-btn">
      <div class="btn" @click="handleEvent">合作咨询</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SubTitle from '@/components/SubTitle.vue';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue';
import StepProcess from '@/components/common/StepProcess.vue';
import { exampleDatas } from '@/config/index';
import store from '@/store';

const yycjData = [
  {
    title: '博物馆',
    url: require('@/assets/images/ar-images/bwg.png')
  },
  {
    title: '艺术馆',
    url: require('@/assets/images/ar-images/ysg.png')
  },
  {
    title: '科技馆',
    url: require('@/assets/images/ar-images/kjg.png')
  },
  {
    title: '历史馆',
    url: require('@/assets/images/ar-images/lsg.png')
  },
  {
    title: '校史馆',
    url: require('@/assets/images/ar-images/xsg.png')
  },
  {
    title: '成果展',
    url: require('@/assets/images/ar-images/cgz.png')
  }
]

const gntxData = [
  {
    title: '互动体验',
    content: '虚拟博物馆提供丰富的互动功能，如虚拟导览、虚拟触摸、语音解说等，让观众能够更深入地了解展品，实现沉浸式探索。',
    url: require('@/assets/images/ar-images/icon/hdty-icon.png')
  },
  {
    title: '教育资源',
    content: '虚拟博物馆是一个宝贵的教育资源，可以为学校、研究机构等提供丰富的历史、文化、艺术等方面的素材，用于课堂教学、学术研究。',
    url: require('@/assets/images/ar-images/icon/jyzy-icon.png')
  },
  {
    title: '文化交流',
    content: '虚拟博物馆，观众可以了解世界各地的历史、文化和艺术，增进对不同文化的理解和尊重，促进不同文化之间的交流和传播。',
    url: require('@/assets/images/ar-images/icon/whjl-icon.png')
  }
]

const handleEvent = () => {
  let contactUsTop: any = store.state.contactUsTop;
  contactUsTop = {
    bottom: 457 + 'rem'
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>
<style scoped lang="less">
.virtual-museum {
  width: 1920rem;
  height: auto;

  .yycj {
    width: 1920rem;
    height: 832rem;
    background: #E1EFFF;
    padding-top: 120rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .yycj-content {
      width: 1109rem;
      height: 510rem;
      margin-top: 24rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      font-size: 0;

      &>div {
        position: relative;
        width: 359rem;
        height: 231rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        &>div {
          position: absolute;
          left: 0;
          top: 187rem;
          width: 100%;
          height: 44rem;
          line-height: 44rem;
          background: rgba(46, 118, 255, 0.7);
          font-size: 20rem;
          color: #fff;
          border-radius: 0px 0px 20rem 20rem;
        }
      }
    }
  }

  .xcxgn {
    width: 1920rem;
    height: 593rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;
    margin-bottom: 120rem;

    .xcxgn-content {
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 366rem;
        height: 262rem;
        border-radius: 12rem;
        overflow: hidden;
        background: url('@/assets/images/ar-images/gntx-bg.png') no-repeat;
        background-size: 100% 100%;
        margin: 46rem 6rem 0;

        .title {
          display: flex;
          align-items: center;
          margin: 48rem 0 14rem 0;
          box-sizing: border-box;
          padding-left: 40rem;
          font-weight: 500;
          font-size: 20rem;
          color: #414141;

          img {
            width: 60rem;
            height: 60rem;
            margin-right: 24rem;
          }
        }

        .content {
          padding: 0 42rem;
          text-align: left;
          font-weight: 400;
          font-size: 14rem;
          color: #3E3E3E;
          line-height: 26rem;
          text-align: justify;
        }
      }
    }
  }
}
</style>