<template>
  <div class="ar-wechat">
    <sub-title title-text="应用场景"></sub-title>
    <div class="secondary-title" style="margin-top: 46rem;">AR营销互动</div>
    <div class="yxhd">
      <main>
        <div class="item" v-for="(item, index) in modelTypes['wechart-ar']" :key="item.header" :style="{marginBottom: index > 2 ? '0' : '46rem'}">
          <img :src="item.image" alt="">
          <div class="content">{{ item.remarkList[0] }}</div>
          <div class="content-title">{{ item.header }}</div>
        </div>
      </main>
    </div>
    <div class="secondary-title">AR教育和培训</div>
    <div class="jyhpx">
      <main>
        <div class="item" v-for="item in modelTypes['wechart-education']" :key="item.header">
          <img :src="item.image" alt="">
          <div class="content">{{ item.remarkList[0] }}</div>
          <div class="content-title">{{ item.header }}</div>
        </div>
      </main>
    </div>
    <div class="secondary-title">AR旅游娱乐</div>
    <div class="lyyl">
      <main>
        <div class="item" v-for="item in modelTypes['wechart-entertainment']" :key="item.header">
          <img :src="item.image" alt="">
          <div class="content">{{ item.remarkList[0] }}</div>
          <div class="content-title">{{ item.header }}</div>
        </div>
      </main>
    </div>
    <div class="secondary-title">AR导航服务</div>
    <div class="dhfw">
      <main>
        <div class="item" v-for="item in modelTypes['wechart-navigation']" :key="item.header">
          <img :src="item.image" alt="">
          <div class="content">{{ item.remarkList[0] }}</div>
          <div class="content-title">{{ item.header }}</div>
        </div>
      </main>
    </div>
    <div class="xcxgn">
      <sub-title mode-class="black-style" title-text="AR小程序功能特性"></sub-title>
      <div class="xcxgn-content">
        <div class="left">
          <header>
            <img src="@/assets/images/ar-images/icon/xz-icon.png" class="l-iamge" alt="">
            <div class="r-head">无需下载APP</div>
          </header>
          <div class="line">用户只需打开微信或其他支持小程序的平台，即可轻松体验AR技术所带来的乐趣。</div>
        </div>
        <div class="right">
          <header>
            <img src="@/assets/images/ar-images/icon/cjsy-icon.png" class="l-iamge" alt="">
            <div class="r-head">全场景适用</div>
          </header>
          <div class="line">快速触发、全场景适用，AR小程序正在各个领域展现出强大的应用潜力和市场价值。</div>
        </div>
      </div>
    </div>
    <example-introduction :exampleData="exampleDatas.arWechat"></example-introduction>
    <step-process :step-title="['沉浸式体验', '一站式部署流程']" step-type="step1"></step-process>
    <div class="counselling-btn">
      <div class="btn" @click="handleEvent">合作咨询</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { modelTypes } from '@/config';
import SubTitle from '@/components/SubTitle.vue';
import ExampleIntroduction from '@/components/common/ExampleIntroduction.vue'
import { exampleDatas } from '@/config/index'
import StepProcess from '@/components/common/StepProcess.vue';
import store from '@/store';

const handleEvent = () => {
  let contactUsTop: any = store.state.contactUsTop;
  contactUsTop = {
    bottom: 457 + 'rem'
  }
  store.state.contactUsTop = JSON.parse(JSON.stringify(contactUsTop))
}
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

.ar-wechat {
  background-color: #E1EFFF;
  padding-top: 116rem;

  .secondary-title {
    font-weight: 500;
    font-size: 24rem;
    color: #414141;
    line-height: 33rem;
    margin: 64rem 0 36rem 0;
  }

  .yxhd,
  .jyhpx,
  .lyyl,
  .dhfw {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    background: #E1EFFF;

    main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1119rem;

      .item {
        position: relative;
        width: 359rem;
        height: 334rem;
        background: #FFFFFF;
        border-radius: 20rem;
        margin-bottom: 46rem;
        overflow: hidden;
        font-size: 0;

        img {
          width: 359rem;
          height: 231rem;
        }

        .content {
          box-sizing: border-box;
          padding: 14rem 22rem 0;
          font-weight: 400;
          font-size: 14rem;
          color: #5C5C5C;
          line-height: 24rem;
          text-align: justify;

          h3 {
            font-weight: 500;
            font-size: 20rem;
            color: #333333;
            margin-bottom: 20rem;
            text-align: left;
            box-sizing: border-box;
            padding-left: 1rem;
          }


          ul {
            padding-left: 20rem;

            li {
              font-size: 14rem;
              color: #5C5C5C;
              text-align: left;
              line-height: 24rem;
            }
          }
        }

        .content-title {
          position: absolute;
          top: 187rem;
          width: 100%;
          height: 44rem;
          line-height: 44rem;
          font-weight: 500;
          font-size: 20rem;
          color: #FFFFFF;
          text-align: center;
          background-color: rgba(46, 118, 255, 0.70);
        }
      }
    }
  }

  .jyhpx,
  .lyyl,
  .dhfw {
    height: 334rem;

    main .item {
      margin: 0 auto;
    }
  }

  .dhfw {
    margin-bottom: 120rem;
  }

  .xcxgn {
    width: 1920rem;
    height: 523rem;
    background-image: url(~@/assets/images/ar-images/aurora-bg.png);
    background-size: 100% 100%;
    padding: 120rem 0 0 0;
    box-sizing: border-box;

    .xcxgn-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        margin-right: 16rem;
      }

      .left,
      .right {
        width: 552rem;
        height: 210rem;
        border-radius: 12rem;
        overflow: hidden;
        background: url('@/assets/images/ar-images/youshiback.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 26rem;

        header {
          display: flex;
          align-items: center;
          margin: 42rem 0 16rem 0;
          box-sizing: border-box;
          padding-left: 42rem;


          .l-iamge {
            width: 56rem;
            height: 54rem;
            margin-right: 24rem;
          }

          .r-head {
            font-weight: 500;
            font-size: 20rem;
            color: #414141;
          }
        }

        .line {
          font-size: 14rem;
          color: #5C5C5C;
          line-height: 24rem;
          text-align: left;
          box-sizing: border-box;
          padding: 0 42rem;
        }
      }
    }
  }
}
</style>