<template>
  <div class="bottom-box">
    <div class="bottom-content">
      <div class="bottom-wrap">
        <div>
          <div class="bottom-logo">
            <span>混空</span>
            <span>Mixed Space</span>
          </div>
          <div class="bottom-codes">
            <div>
              <img src="@/assets/images/code1.png" />
              <div>公众号</div>
            </div>
            <div>
              <img src="@/assets/images/code2.png" />
              <div>视频号</div>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div v-for="(item, index) in linkList" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="url-list" v-for="(e, i) in item.value" :key="i" :class="e.type || ''"
              @click="changeRouter(e, e.titleIndex)">{{ e.name }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="company-address">上海市徐汇区钦州北路1001号光启园7号楼6层</div>
      <div class="filings">
        <div>
          <span style="color: #929292;"
            @click="to1('https://beian.miit.gov.cn/#/Integrated/index')">沪ICP备2023012463号—1</span>
          <span>版权所有 ©️ 2023上海凝聚元界信息科技有限公司</span>
          <img src="@/assets/images/beian-icon.png" />
          <span style="color: #929292;" @click="to1('https://beian.mps.gov.cn/#/query/webSearch')">沪公网安备
            31010402010108号</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const linkList: any = [
  {
    title: '产品服务',
    value: [
      {
        name: '混空 Studio',
        path: '/studio'
      }
    ]
  },
  {
    title: '通用解决方案',
    value: [
      {
        name: '空间视觉技术',
        path: '/solution',
        index: 3,
      },
      {
        name: 'VR虚拟仿真',
        path: '/solution',
        index: 5,
      },
      {
        name: 'AR导航导览',
        path: '/solution',
        index: 7,
      },
      {
        name: 'AR小程序',
        path: '/solution',
        index: 4,
      },
      {
        name: '虚拟博物馆',
        path: '/solution',
        index: 6,
      },
      {
        name: '元宇宙展厅',
        path: '/solution',
        index: 8,
      }
    ]
  },
  {
    title: '行业解决方案',
    value: [
      {
        name: '文娱解决方案',
        path: '/solution',
        index: 0,
      },
      {
        name: '工业解决方案',
        path: '/solution',
        index: 1,
      },
      {
        name: '教育解决方案',
        path: '/solution',
        index: 2,
      },
    ]
  },
  {
    title: '关于我们',
    value: [
      {
        name: '公司介绍',
        path: '/about-us',
      },
      {
        name: '公司新闻',
        path: '/company-news',
        index: 5,
      },
      {
        name: '行业新闻',
        path: '/company-news',
        index: 6,
      },
      {
        name: '隐私政策',
        path: '/about-us-privacy_policy',
      }
    ]
  },
  {
    title: '合作咨询',
    value: [
      {
        name: '座机',
        path: '',
        type: 'phone-title'
      },
      {
        name: '400-900-6269',
        path: '',
        type: 'phone'
      }
    ]
  },
]

const to1 = (url: string) => {
  window.scrollTo(0, 0)
  window.location.href = url
}

const changeRouter = (e: any, titleIndex: number) => {
  if (!e.path) return
  window.scrollTo(0, 0);
  if (e.path == '/solution' || e.path == '/company-news') {
    router.push(`${e.path}?type=${e.index}`)
    store.state.programmeIndex = e.index
  } else {
    router.push(`${e.path}`);
  }
  window.localStorage.setItem('activePath', e.path)
  store.state.activePath = e.path;
}
</script>
<style scoped lang="less">
.bottom-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  // height: 276rem;
  padding-bottom: 20rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .bottom-content {
    width: calc(843rem + 17vw);
    height: 100%;

    .bottom-wrap {
      display: flex;
      justify-content: space-between;
      align-items: start;
      padding-bottom: 10rem;
      padding-right: 35rem;
      box-sizing: border-box;

      .bottom-logo {
        width: 309px;
        height: 44px;
        text-align: left;
        font-weight: 600;
        font-size: 22px;
        color: #FFFFFF;
        margin: 50rem 0 15rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &>span:first-child {
          margin-right: 20rem;
        }
      }

      .bottom-codes {
        width: 251px;
        margin-left: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14rem;
        color: #FFFFFF;
      }

      img {
        width: 120px;
        height: 120px;
        margin-bottom: 8rem;
        transition: all .5s;
      }

      // img:hover {
      //   scale: 1.2
      // }

      .bottom-right {
        width: 612rem;
        font-family: PingFangSC-Regular;
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 51rem;
        text-align: left;

        div {
          margin-bottom: 20rem;
          font-weight: 500;
          font-size: 14rem;
          color: #9C9C9C;
        }

        .url-list.phone-title {
          color: rgba(255, 255, 255, 0.5);
        }

        .url-list.phone {
          margin-top: -15rem;
        }

        .title {
          font-weight: 800;
          font-size: 18rem;
          color: #FFFFFF;
          margin-bottom: 20rem;
        }

        .blod {
          font-weight: 800;
          font-size: 18rem;
          color: #FFFFFF;
        }
      }
    }

    .line {
      width: 100%;
      height: 0.5px;
      background: rgba(203, 203, 203, 0.41);
      margin-bottom: 19rem;
    }

    .company-address {
      font-weight: 500;
      font-size: 14rem;
      color: #929292;
      margin-bottom: 6rem;
    }

    .filings {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        font-family: PingFangSC-Regular;
        margin: 0 15rem;
        font-weight: 400;
        font-size: 14rem;
        color: #929292;
      }

      span {
        cursor: pointer;
        text-decoration: underline;
        vertical-align: middle;
      }

      img {
        width: 16rem;
        height: 16rem;
        margin: 0 2rem 0 5rem;
        vertical-align: middle;
      }
    }

    .url-list {
      cursor: pointer;
    }
  }
}
</style>