<template>
  <div class="counselling">
    <div class="counselling-banner">
      <img :src="loadUrl.banner_bg" />
      <div>合作咨询</div>
    </div>
    <div v-if="showCode">
      <div class="contact-us-box">
        <div class="title">联系我们的团队</div>
        <div>你可以通过以下几种方式我们，我们将为您提供专业的解答</div>
      </div>
      <div class="code-box">
        <div>
          <div>
            <div>扫码添加 客服微信</div>
            <div style="position: relative; left: -10rem;"><img src="@/assets/images/home/customer.jpg" /></div>
          </div>
          <div>
            <div>购买咨询</div>
            <div>
              <div>400-900-6269</div>
              <div class="code-box-time">工作日：9:30-18:30（北京时间）</div>
            </div>
          </div>
          <div>
            <div>联系邮箱</div>
            <div>
              <div>yang.he@njyjxr.com</div>
              <div class="code-box-time">48小时之内回复</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showCode" class="form-box">
      <div class="title">成功提交信息，可添加客服微信提供一对一支持</div>
      <div class="form-wrap">
        <el-input class="form-input" :class="checkEmpty.companyName ? 'error-cn' : ''" v-model="contactInfo.companyName"
          placeholder="公司名称" />
        <el-input class="form-input" :class="checkEmpty.name ? 'error-n' : ''" v-model="contactInfo.name"
          placeholder="姓名" />
        <el-input class="form-input" :class="showError || checkEmpty.phone ? 'error-text' : ''"
          v-model="contactInfo.phone" placeholder="电话" />
        <el-select placeholder="所属行业" :class="checkEmpty.industry ? 'error-i' : ''" v-model="contactInfo.industry"
          class="select-default" popper-class="select-option">
          <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.value" />
        </el-select>
      </div>
      <div class="submit-btn" @click="submitEvent">提交信息</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, reactive } from 'vue'
import { asynchronousRetrieval } from '@/utils'
import { addConsult } from '@/api'

const loadUrl: any = ref({})
const showCode = ref(false)
const checkEmpty: any = ref({})
const contactInfo: any = reactive({
  companyName: '',
  name: '',
  phone: '',
  industry: null
})
const showError = ref(false)
const industryList = [
  {
    name: '文娱',
    value: 1,
  },
  {
    name: '工业',
    value: 2,
  },
  {
    name: '教育',
    value: 3,
  },
  {
    name: '其他',
    value: 4,
  }
]

const submitEvent = () => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  Object.keys(contactInfo).forEach((k) => {
    checkEmpty.value[k] = !contactInfo[k]
  })
  if (!reg.test(contactInfo.phone.replace(/\s+/g, "")) || !contactInfo.phone) {
    showError.value = true;
    return
  } else if (!contactInfo.companyName || !contactInfo.name || !contactInfo.industry) {
    return
  }
  addConsult({ ...contactInfo }).then((res: any) => {
    contactInfo.companyName = ''
    contactInfo.name = ''
    contactInfo.phone = ''
    contactInfo.industry = null
    showError.value = false;
    checkEmpty.value = {}
    showCode.value = true;
  })
}


onMounted(async () => {
  const banner_bg: any = await asynchronousRetrieval('webimg/images/about_us/banner-bg.png');
  loadUrl.value = { banner_bg }
})
</script>
<style scoped lang="less">
.counselling {
  background-color: #F7F7F7;

  .counselling-banner {
    position: relative;
    height: 208rem;
    line-height: 208rem;
    text-align: center;
    font-weight: 600;
    font-size: 40rem;
    color: #FFFFFF;


    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      position: relative;
      z-index: 1;
    }
  }

  .contact-us-box {
    padding: 58rem 60rem 32rem;
    font-weight: 400;
    font-size: 24rem;
    color: #646464;
    line-height: 36rem;
    text-align: center;

    .title {
      font-weight: bold;
      font-size: 40rem;
      color: #000000;
      line-height: 58rem;
      margin-bottom: 16rem;
    }
  }

  .code-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 32rem;

    &>div {
      width: 606rem;
      height: 704rem;
      background: #FFFFFF;
      border-radius: 16rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 64rem 46rem 70rem;
      box-sizing: border-box;

      &>div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &>div:first-child {
          font-weight: bold;
          font-size: 28rem;
          color: #414141;
          line-height: 32rem;
          width: 112rem;
          margin-right: 24rem;
          position: relative;
          top: 20rem;
        }

        &>div:last-child {
          text-align: left;
          font-weight: bold;
          font-size: 40rem;
          color: #414141;
          line-height: 1;
        }

        .code-box-time {
          font-weight: 500;
          font-size: 24rem;
          color: #7C7C7C;
          line-height: 36rem;
          margin-top: 12rem;
        }

        img {
          width: 236rem;
          height: 238rem;
        }
      }
    }
  }

  .form-box {
    padding-top: 66rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      width: 448rem;
      font-weight: 500;
      font-size: 32rem;
      color: #000000;
      line-height: 48rem;
      text-align: center;
      margin-bottom: 88rem;
    }

    .form-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 26rem;

      &>div {
        position: relative;
        background: #fff;
        border-radius: 5rem;
        margin-bottom: 24rem;
        color: #414141;
      }

      ::v-deep(.el-input__wrapper) {
        width: 534rem;
        height: 68rem;
        padding-left: 30rem;

        input {
          color: #414141;
          font-size: 24rem;
        }
      }

      ::v-deep(.el-select__wrapper) {
        height: 72rem;
        width: 534rem;
        padding-left: 30rem;

        div {
          span {
            font-size: 24rem;
            color: #414141;
          }
        }

        .is-transparent>span {
          color: #a8abb2;
          font-weight: 400;
        }

        .el-select__suffix>i {
          width: 20rem;
        }
      }
    }

    .submit-btn {
      width: 158rem;
      height: 60rem;
      background: #0675FF;
      border-radius: 6rem;
      line-height: 60rem;
      text-align: center;
      font-weight: 500;
      font-size: 24rem;
      color: #FFFFFF;
      margin: 24rem 0 66rem;
      box-sizing: border-box;
    }
  }
}
</style>